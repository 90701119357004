<div class="header">
  <div class="heading">
    Property To Be Mortgaged

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<div *ngIf="data.propertyDetails?.propertyUnknown" class="notReady">
  Client does not yet know the property details
</div>

<div *ngIf="data.propertyDetails && !data.propertyDetails.propertyUnknown" class="content">
  <div class="column">
    <div class="record">
      <span class="label">House</span>
      <span class="value">{{ data.propertyDetails.address.house }}</span>
    </div>
    <div class="record">
      <span class="label">Street</span>
      <span class="value">{{ data.propertyDetails.address.street }}</span>
    </div>
    <div *ngIf="data.propertyDetails.address.line2" class="record">
      <span class="label">Address Line 2</span>
      <span class="value">{{ data.propertyDetails.address.line2 }}</span>
    </div>
    <div class="record">
      <span class="label">City</span>
      <span class="value">{{ data.propertyDetails.address.city }}</span>
    </div>
    <div class="record">
      <span class="label">Region</span>
      <span class="value">{{ REGION_TYPE_NAME.get(data.propertyDetails.address.region) }}</span>
    </div>
    <div class="record">
      <span class="label">Country</span>
      <span class="value">{{ data.propertyDetails.address.country }}</span>
    </div>
    <div class="record">
      <span class="label">Postcode</span>
      <span class="value">{{ data.propertyDetails.address.postcode }}</span>
    </div>

    <div class="record">
      <span class="label">Purchase Price</span>
      <span class="value">{{ data.propertyDetails.purchasePrice | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
    </div>
    <div *ngIf="data.propertyDetails.currentValuation" class="record">
      <span class="label">Current Valuation</span>
      <span class="value">{{ data.propertyDetails.currentValuation | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
    </div>
    <div *ngIf="data.propertyDetails.propertyRemortgageIntent" class="record">
      <span class="label">Which of the following are you looking to do?</span>
      <span class="value">{{ PropertyRemortgageIntentName.get(data.propertyDetails.propertyRemortgageIntent) }}</span>
    </div>
    <div class="record">
      <span class="label">{{ getToBorrowLabel() }}</span>
      <span class="value">{{ data.propertyDetails.toBorrow | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
    </div>

    <div *ngIf="data.propertyDetails.reasonForIncreasingBorrowing" class="record">
      <span class="label">Please confirm the reason for increasing</span>
      <span class="value">{{ data.propertyDetails.reasonForIncreasingBorrowing }}</span>
    </div>
    <div *ngIf="data.propertyDetails.sourceOfReducedBorrowingFunds" class="record">
      <span class="label">Please confirm the source of funds which will enable you to reduce your mortgage balance</span>
      <span class="value">{{ data.propertyDetails.sourceOfReducedBorrowingFunds }}</span>
    </div>

    <div *ngIf="data.propertyDetails?.depositSources?.length" class="record">
      <span class="label">Deposit Sources</span>
      <span class="value" [innerHTML]="getDepositSourceValues(data.propertyDetails)"></span>
    </div>


    <div *ngIf="data.propertyDetails.overTerm" class="record">
      <span class="label">Over term</span>
      <span class="value">{{ data.propertyDetails.overTerm.years}} years, {{data.propertyDetails.overTerm.months }} months</span>
    </div>
    <div class="record">
      <span class="label">Preferred Product Type</span>
      <span class="value">{{ PRODUCT_TYPE_NAME.get(data.propertyDetails.productType) }}</span>
    </div>
    <div class="record">
      <span class="label">Payment Method</span>
      <span class="value">{{ PROPERTY_PAYMENT_METHOD_NAME.get(data.propertyDetails.paymentMethod) }}</span>
    </div>

    <div *ngIf="interestOnlyOptions" class="record">
      <span class="label">{{ getInterestOnlyLabel() }}</span>
      <span class="value">{{ interestOnlyOptions }}</span>
    </div>
  </div>

  <div class="column">
    <div class="record">
      <span class="label">Property Type</span>
      <span class="value">{{ PROPERTY_TYPE_NAME.get(data.propertyDetails.propertyType) }}</span>
    </div>
    <div *ngIf="data.propertyDetails.propertyType === 'HOUSE'" class="record">
      <span class="label">House Type</span>
      <span class="value">{{ PROPERTY_HOUSE_TYPE_NAME.get(data.propertyDetails.houseType) }}</span>
    </div>

    <ng-container *ngIf="data.propertyDetails.propertyType === 'FLAT_MAISONETTE'">
      <div class="record">
        <span class="label">Flat Type</span>
        <span class="value">{{ PROPERTY_FLAT_TYPE_NAME.get(data.propertyDetails.flatType) }}</span>
      </div>
      <div class="record">
        <span class="label">Flat ex-local authority</span>
        <span class="value">{{ yesno(data.propertyDetails.exLocal) }}</span>
      </div>
      <div class="record">
        <span class="label">Number of Stories</span>
        <span class="value">{{ data.propertyDetails.storiesNum }}</span>
      </div>
      <div class="record">
        <span class="label">Annual Ground Rent</span>
        <span class="value">{{ data.propertyDetails.annualGroundRent | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
      </div>
      <div class="record">
        <span class="label">Monthly Service Charge</span>
        <span class="value">{{ data.propertyDetails.monthlyServiceCharge | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
      </div>
    </ng-container>

    <div class="record">
      <span class="label">Property Tenure</span>
      <span class="value">{{ PROPERTY_TENURE_NAME.get(data.propertyDetails.propertyTenure) }}</span>
    </div>
    <div *ngIf="data.propertyDetails.remainingOnLease" class="record">
      <span class="label">Remaining Lease On</span>
      <span class="value">{{data.propertyDetails.remainingOnLease.years}} years, {{data.propertyDetails.remainingOnLease.months}} months</span>
    </div>
    <div class="record">
      <span class="label">Number of bedrooms</span>
      <span class="value">{{ PROPERTY_BEDROOMS_NAME.get(data.propertyDetails.bedrooms) }}</span>
    </div>

    <div class="record">
      <span class="label">Property Built</span>
      <span class="value">{{ data.propertyDetails.propertyBuild }}</span>
    </div>
    <div *ngIf="data.propertyDetails.anticipatedRentalIncome" class="record">
      <span class="label">Anticipated Rental Income (Annual)</span>
      <span class="value">{{ data.propertyDetails.anticipatedRentalIncome | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
    </div>
    <div class="record">
      <span class="label">No claims discount</span>
      <span class="value">{{ data.propertyDetails.noClaimsDiscounts }} years</span>
    </div>
  </div>

  <div class="column">
    <div  class="record">
      <span class="label">Agent or Builder</span>
      <span class="value">{{ yesno(data.propertyDetails.buyingThoughEstateAgent) }}</span>
    </div>

    <ng-container *ngIf="data.propertyDetails.buyingThoughEstateAgent">
      <div class="record">
        <span class="label">Estate Agency Name</span>
        <span class="value">{{ data.propertyDetails.estateAgentName }}</span>
      </div>
      <div class="record">
        <span class="label">Estate Agent Contact Name</span>
        <span class="value">{{ data.propertyDetails.estateAgentContactName }}</span>
      </div>

      <div class="record">
        <span class="label">House</span>
        <span class="value">{{ data.propertyDetails.estateAgentAddress.house }}</span>
      </div>
      <div class="record">
        <span class="label">Street</span>
        <span class="value">{{ data.propertyDetails.estateAgentAddress.street }}</span>
      </div>
      <div *ngIf="data.propertyDetails.estateAgentAddress.line2" class="record">
        <span class="label">Address Line 2</span>
        <span class="value">{{ data.propertyDetails.estateAgentAddress.line2 }}</span>
      </div>
      <div class="record">
        <span class="label">City</span>
        <span class="value">{{ data.propertyDetails.estateAgentAddress.city }}</span>
      </div>
      <div class="record">
        <span class="label">Country</span>
        <span class="value">{{ data.propertyDetails.estateAgentAddress.country }}</span>
      </div>
      <div class="record">
        <span class="label">Postcode</span>
        <span class="value">{{ data.propertyDetails.estateAgentAddress.postcode }}</span>
      </div>

      <div class="record">
        <span class="label">Phone</span>
        <span class="value">{{ data.propertyDetails.estateAgentPhonePrefix }} {{ data.propertyDetails.estateAgentPhone }}</span>
      </div>
      <div class="record">
        <span class="label">Email</span>
        <span class="value">{{ data.propertyDetails.estateAgentEmail }}</span>
      </div>

      <div class="record">
        <span class="label">Incentives Being Offered</span>
        <span class="value">{{ yesno(data.propertyDetails.incentivesBeingOffered) }}</span>
      </div>
    </ng-container>
  </div>

  <div class="column">
    <div  class="record">
      <span class="label">Own Solicitor</span>
      <span class="value">{{ yesno(data.propertyDetails.ownSolicitor) }}</span>
    </div>

    <ng-container *ngIf="data.propertyDetails.ownSolicitor">
      <div class="record">
        <span class="label">Solicitor Name</span>
        <span class="value">{{ data.propertyDetails.solicitor.name }}</span>
      </div>
      <div class="record">
        <span class="label">House</span>
        <span class="value">{{ data.propertyDetails.solicitor.address.house }}</span>
      </div>
      <div class="record">
        <span class="label">Street</span>
        <span class="value">{{ data.propertyDetails.solicitor.address.street }}</span>
      </div>
      <div *ngIf="data.propertyDetails.solicitor.address.line2" class="record">
        <span class="label">Address Line 2</span>
        <span class="value">{{ data.propertyDetails.solicitor.address.line2 }}</span>
      </div>
      <div class="record">
        <span class="label">City</span>
        <span class="value">{{ data.propertyDetails.solicitor.address.city }}</span>
      </div>
      <div class="record">
        <span class="label">Country</span>
        <span class="value">{{ data.propertyDetails.solicitor.address.country }}</span>
      </div>
      <div class="record">
        <span class="label">Postcode</span>
        <span class="value">{{ data.propertyDetails.solicitor.address.postcode }}</span>
      </div>

      <div class="record">
        <span class="label">Phone</span>
        <span class="value">{{ data.propertyDetails.solicitor.phonePrefix }} {{ data.propertyDetails.solicitor.phone }}</span>
      </div>
      <div class="record">
        <span class="label">Email</span>
        <span class="value">{{ data.propertyDetails.solicitor.email }}</span>
      </div>
    </ng-container>
  </div>
</div>
