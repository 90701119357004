<div class="header">
  <div class="heading">
    Assets

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<ng-container *ngIf="config.stepStatus !== 'NOT_STARTED'">
  <div *ngFor="let asset of data.assets" class="content repeated">
    <div class="header-2">{{ asset.otherTypeName || ASSET_TYPE_NAME.get(asset.type) }}</div>

    <div *ngIf="asset.assetValue" class="column">
      <div class="record">
        <span class="label">Value</span>
        <span class="value">{{ asset.assetValue | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
      </div>
    </div>

    <ng-container *ngIf="asset.propertyDetails && asset.type === 'PROPERTY'">
      <div class="column">
        <div class="record">
          <span class="label">Mortgage on property</span>
          <span class="value">{{ yesno(asset.propertyDetails.mortgageOnProperty) }}</span>
        </div>

        <ng-container *ngIf="asset.propertyDetails.mortgageOnProperty">
          <div class="record">
            <span class="label">Lender</span>
            <span class="value">{{ asset.propertyDetails.lender }}</span>
          </div>
          <div *ngIf="asset.propertyDetails.mortgageAccountNumber" class="record">
            <span class="label">Mortgage Account Number</span>
            <span class="value">{{ asset.propertyDetails.mortgageAccountNumber }}</span>
          </div>
          <div class="record">
            <span class="label">Remaining Loan Amount</span>
            <span class="value">{{ asset.propertyDetails.loanAmount | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
          </div>
          <div class="record">
            <span class="label">Monthly Payment</span>
            <span class="value">{{ asset.propertyDetails.monthlyPayment }}</span>
          </div>
          <div class="record">
            <span class="label">Remaining Mortgage Term</span>
            <span class="value">{{ asset.propertyDetails.remainingMortgage.years }} years, {{ asset.propertyDetails.remainingMortgage.months }} months</span>
          </div>
          <div *ngIf="asset.propertyDetails.productEndDate" class="record">
            <span class="label">Product End Date</span>
            <span class="value">{{ asset.propertyDetails.productEndDate | date:'d MMM y' }}</span>
          </div>
        </ng-container>

        <div class="record">
          <span class="label">Renting out</span>
          <span class="value">{{ yesno(asset.propertyDetails.rentingProperty) }}</span>
        </div>
        <div *ngIf="asset.propertyDetails.rentingProperty" class="record">
          <span class="label">Rent amount</span>
          <span class="value">{{ asset.propertyDetails.rentAmount }}</span>
        </div>

        <div *ngIf="!asset.propertyDetails.rentingProperty" class="record">
          <span class="label">Status</span>
          <span class="value">{{ asset.propertyDetails.otherStatus || PROPERTY_ASSET_STATUS_NAME.get(asset.propertyDetails.status) }}</span>
        </div>

        <div class="record">
          <span class="label">Property Type</span>
          <span class="value">{{ PROPERTY_TYPE_NAME.get(asset.propertyDetails.propertyType) }}</span>
        </div>
        <div *ngIf="asset.propertyDetails.propertyType === 'HOUSE'" class="record">
          <span class="label">House Type</span>
          <span class="value">{{ PROPERTY_HOUSE_TYPE_NAME.get(asset.propertyDetails.houseType) }}</span>
        </div>

        <ng-container *ngIf="asset.propertyDetails.propertyType === 'FLAT_MAISONETTE'">
          <div class="record">
            <span class="label">Flat Type</span>
            <span class="value">{{ PROPERTY_FLAT_TYPE_NAME.get(asset.propertyDetails.flatType) }}</span>
          </div>
          <div class="record">
            <span class="label">Flat ex-local authority</span>
            <span class="value">{{ yesno(asset.propertyDetails.exLocal) }}</span>
          </div>
          <div class="record">
            <span class="label">Number of Stories</span>
            <span class="value">{{ asset.propertyDetails.storiesNum }}</span>
          </div>
          <div class="record">
            <span class="label">Annual Ground Rent</span>
            <span class="value">{{ asset.propertyDetails.annualGroundRent | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
          </div>
          <div class="record">
            <span class="label">Monthly Service Charge</span>
            <span class="value">{{ asset.propertyDetails.monthlyServiceCharge | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
          </div>
        </ng-container>

        <div class="record">
          <span class="label">Property Tenure</span>
          <span class="value">{{ PROPERTY_TENURE_NAME.get(asset.propertyDetails.propertyTenure) }}</span>
        </div>
        <div *ngIf="asset.propertyDetails.remainingOnLease" class="record">
          <span class="label">Remaining Lease On</span>
          <span class="value">{{asset.propertyDetails.remainingOnLease.years}} years, {{asset.propertyDetails.remainingOnLease.months}} months</span>
        </div>
        <div class="record">
          <span class="label">Number of bedrooms</span>
          <span class="value">{{ PROPERTY_BEDROOMS_NAME.get(asset.propertyDetails.bedrooms) }}</span>
        </div>

        <div class="record">
          <span class="label">Value</span>
          <span class="value">{{ asset.propertyDetails.propertyValue | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
        </div>
        <div *ngIf="asset.propertyDetails.purchaseDate" class="record">
          <span class="label">Purchase Date</span>
          <span class="value">{{ asset.propertyDetails.purchaseDate | date:'d MMM y' }}</span>
        </div>
      </div>

      <div class="column">
        <div class="record">
          <span class="label">House</span>
          <span class="value">{{ asset.propertyDetails.address.house }}</span>
        </div>
        <div class="record">
          <span class="label">Street</span>
          <span class="value">{{ asset.propertyDetails.address.street }}</span>
        </div>
        <div class="record">
          <span class="label">Address Line 2</span>
          <span class="value">{{ asset.propertyDetails.address.line2 }}</span>
        </div>
        <div class="record">
          <span class="label">City</span>
          <span class="value">{{ asset.propertyDetails.address.city }}</span>
        </div>
        <div class="record">
          <span class="label">Country</span>
          <span class="value">{{ asset.propertyDetails.address.country }}</span>
        </div>
        <div class="record">
          <span class="label">Postcode</span>
          <span class="value">{{ asset.propertyDetails.address.postcode }}</span>
        </div>

        <div class="record">
          <span class="label">Selling in line with new mortgage</span>
          <span class="value">{{ yesno(asset.propertyDetails.willBeSelling) }}</span>
        </div>
        <div *ngIf="asset.propertyDetails.willBeSelling" class="record">
          <span class="label">Using for purchase</span>
          <span class="value">{{ yesno(asset.propertyDetails.willBeUsingForPurchase) }}</span>
        </div>
        <div class="record">
          <span class="label">Remortgaging property</span>
          <span class="value">{{ yesno(asset.propertyDetails.remortgagingProperty) }}</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!data.assets?.length" class="empty">
    There are no assets
  </div>
</ng-container>
