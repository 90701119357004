<ng-container *ngFor="let account of category.accounts">
  <div class="cd-h2">{{ account.company.name }}</div>

  <div class="cd-details-container">
    <div class="cd-details-column">
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Current balance</div>
        <div class="cd-details-entry-value">{{ account.currentBalance | customCurrency:'GBP':true:'1.0-2' }}</div>
      </div>
    </div>
    <div class="cd-details-column">
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Status</div>
        <div class="cd-details-entry-value">{{ CreditAccountStatusName.get(account.paymentStatus) }}</div>
      </div>
    </div>
  </div>

  <div class="cd-separator"></div>

  <div class="cd-h3">Account Details</div>

  <div class="cd-details-container columns-4">
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Start date</div>
        <div class="cd-details-entry-value">{{ account.startDate | date:'dd/MM/y' }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">End date</div>
        <div class="cd-details-entry-value">{{ account.endDate | date:'dd/MM/y' | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Number of payments</div>
        <div class="cd-details-entry-value">{{ account.numberOfPayments }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Payment amount</div>
        <div class="cd-details-entry-value">{{ account.paymentAmount | customCurrency:'GBP':true:'1.0-2' }}</div>
      </div>

      <div class="cd-breaker"></div>

      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Missed payments within 6 months</div>
        <div class="cd-details-entry-value">{{ account.missedPayments6Months | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Missed payments within 12 months</div>
        <div class="cd-details-entry-value">{{ account.missedPayments12Months | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Missed payments within 24 months</div>
        <div class="cd-details-entry-value">{{ account.missedPayments24Months | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Missed payments within 36 months</div>
        <div class="cd-details-entry-value">{{ account.missedPayments36Months | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Missed payments within 60 months</div>
        <div class="cd-details-entry-value">{{ account.missedPayments60Months | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Missed payments within 72 months</div>
        <div class="cd-details-entry-value">{{ account.missedPayments72Months | valueOrDash }}</div>
      </div>

      <div class="cd-breaker"></div>

      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Defaults within 6 months</div>
        <div class="cd-details-entry-value">{{ account.defaultPayments6Months | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Defaults within 12 months</div>
        <div class="cd-details-entry-value">{{ account.defaultPayments12Months | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Defaults within 60 months</div>
        <div class="cd-details-entry-value">{{ account.defaultPayments60Months | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Defaults within 72 months</div>
        <div class="cd-details-entry-value">{{ account.defaultPayments72Months | valueOrDash }}</div>
      </div>

      <div class="cd-breaker"></div>

      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Initial balance</div>
        <div class="cd-details-entry-value">{{ account.initialBalance | customCurrency:'GBP':true:'1.0-2' | valueOrDash }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Credit limit balance</div>
        <div class="cd-details-entry-value">{{ account.creditLimitBalance | customCurrency:'GBP':true:'1.0-2' }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Payment frequency</div>
        <div class="cd-details-entry-value">{{ account.paymentFrequency }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Default balance</div>
        <div class="cd-details-entry-value">{{ account.defaultBalance | customCurrency:'GBP':true:'1.0-2' }}</div>
      </div>

      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Last update</div>
        <div class="cd-details-entry-value">{{ account.lastUpdate | date:'dd/MM/y' }}</div>
      </div>
  </div>

  <div class="cd-separator"></div>

  <ng-cotainer *ngIf="account.balances?.length">
    <div class="cd-h3">Account Balance</div>

    <hf-account-balance-table [account]="account"></hf-account-balance-table>
  </ng-cotainer>
</ng-container>
