<hf-category-details>
  <div class="cd-details-container">
    <div class="cd-details-column">
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Total unsecured debt</div>
        <div class="cd-details-entry-value">{{ report.totalUnsecuredDebt | customCurrency:'GBP':true:'1.0-2' }}</div>
      </div>
    </div>
  </div>

  <div class="cd-header cd-h1">
    Accounts
  </div>

  <hf-account-details [category]="report.loans"></hf-account-details>
</hf-category-details>
