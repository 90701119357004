import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EvergladesSharedMorule } from "../../../../../src/app/website/shared/everglades-shared.module";
import { ClientSharedModule } from "../shared/client-shared.module";
import { SharedModule } from "../shared/shared.module";
import { AddressReviewSectionComponent } from "./components/steps/sign-off-component/components/address-review-section/address-review-section.component";
import { AgreeAndSignReviewSectionComponent } from "./components/steps/sign-off-component/components/agree-and-sign-review-section/agree-and-sign-review-section.component";
import { AmlResultSmallComponent } from "./components/steps/sign-off-component/components/aml-result-small/aml-result-small.component";
import { AmlResultComponent } from "./components/steps/sign-off-component/components/aml-result/aml-result.component";
import { ApplicantDocumentsReviewSectionComponent } from "./components/steps/sign-off-component/components/applicant-documents-review-section/applicant-documents-review-section.component";
import { ApplicantOutgoingsReviewSectionComponent } from "./components/steps/sign-off-component/components/applicant-outgoings-review-section/applicant-outgoings-review-section.component";
import { ApplicationDetailsReviewSectionComponent } from "./components/steps/sign-off-component/components/application-details-review-section/application-details-review-section.component";
import { AssetsReviewSectionComponent } from "./components/steps/sign-off-component/components/assets-review-section/assets-review-section.component";
import { CommonDocumentsReviewSectionComponent } from "./components/steps/sign-off-component/components/common-documents-review-section/common-documents-review-section.component";
import { CreditReviewSectionComponent } from "./components/steps/sign-off-component/components/credit-review-section/credit-review-section.component";
import { DependantsReviewSectionComponent } from "./components/steps/sign-off-component/components/dependants-review-section/dependants-review-section.component";
import { IncomeReviewSectionComponent } from "./components/steps/sign-off-component/components/income-review-section/income-review-section.component";
import { PaymentReviewSectionComponent } from "./components/steps/sign-off-component/components/payment-review-section/payment-review-section.component";
import { PersonalDetailsReviewSectionComponent } from "./components/steps/sign-off-component/components/personal-details-review-section/personal-details-review-section.component";
import { PropertyReviewSectionComponent } from "./components/steps/sign-off-component/components/property-review-section/property-review-section.component";
import { ReadonlySignatureReviewSectionComponent } from "./components/steps/sign-off-component/components/readonly-signature-review-section/readonly-signature-review-section.component";
import { SignOffContentComponent } from "./components/steps/sign-off-component/components/sign-off-content/sign-off-content.component";
import { TotalOutgoingsReviewSectionComponent } from "./components/steps/sign-off-component/components/total-outgoings-review-section/total-outgoings-review-section.component";

const exported = [
  PersonalDetailsReviewSectionComponent,
  DependantsReviewSectionComponent,
  AddressReviewSectionComponent,
  IncomeReviewSectionComponent,
  TotalOutgoingsReviewSectionComponent,
  ApplicantOutgoingsReviewSectionComponent,
  CreditReviewSectionComponent,
  AssetsReviewSectionComponent,
  PropertyReviewSectionComponent,
  PaymentReviewSectionComponent,
  ApplicantDocumentsReviewSectionComponent,
  CommonDocumentsReviewSectionComponent,
  ApplicationDetailsReviewSectionComponent,
  AgreeAndSignReviewSectionComponent,
  ReadonlySignatureReviewSectionComponent,
  AmlResultComponent,
  AmlResultSmallComponent,
  SignOffContentComponent,
]

@NgModule({
  declarations: exported,
  exports: exported,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ClientSharedModule,
    EvergladesSharedMorule
  ]
})
export class FactFindSharedModule { }
