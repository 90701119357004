<hf-category-details>
  <div class="cd-details-container">
    <div class="cd-details-column">
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Mortgage amount</div>
        <div class="cd-details-entry-value">{{ report.mortgageAmount | customCurrency:'GBP':true:'1.0-2' }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Mortgage term</div>
        <div class="cd-details-entry-value">{{ report.mortgageTermMonths }} months</div>
      </div>
    </div>
    <div class="cd-details-column">
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Missed mortgage payments</div>
        <div class="cd-details-entry-value">{{ report.missedMortgagePayments }}</div>
      </div>
    </div>
  </div>

  <div class="cd-header cd-h1">
    Accounts
  </div>

  <hf-account-details [category]="report.mortgages"></hf-account-details>
</hf-category-details>
