<div class="header">
  <div class="heading">
    Total Monthly Outgoings
  </div>
</div>

<div class="content">
  <div *ngFor="let outgoing of totalOutgoings" class="column">
    <div class="record">
      <span class="label">{{ OUTGOINGS_CATEGORY_NAME.get(outgoing.category) }}</span>
      <span class="value">{{ outgoing.amount | customCurrency:"GBP":undefined:"1.0-2"  }}</span>
    </div>
  </div>

  <div class="column">
    <div class="record">
      <span class="label">Total</span>
      <span class="value">{{ grandTotal | currency :"GBP":undefined:"1.0-2"  }}</span>
    </div>
  </div>
</div>
