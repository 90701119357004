import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { SelectDateAndTimeModule } from "../modules/mortgages/select-date-and-time/select-date-and-time.module";
import { CustomButtonModule } from "../shared/custom-button/custom-button.module";
import { CustomTextFieldModule } from "../shared/custom-text-field/custom-text-field.module";
import { CustomTextAreaModule } from "../shared/custom-textarea/custom-text-area.module";
import { DatePickerModule } from "../shared/date-picker/date-picker.module";
import { DocumentsZipButtonModule } from '../shared/documents-zip-button/documents-zip-button.module';
import { HfCheckboxModule } from "../shared/hf-checkbox-component/hf-checkbox.module";
import { ItemsPerPageSelectorModule } from '../shared/item-per-page-selector/items-per-page-selector.module';
import { PaginationSummaryBoxModule } from '../shared/pagination-summary-box/pagination-summary-box.module';
import { PaginatorModule } from '../shared/paginator/paginator.module';
import { SharedModule } from "../shared/shared.module";
import { AddTaskComponent } from "./side-panes/add-task/add-task.component";
import { DocumentsTabComponent } from './tabs/documents/documents-tab.component';
import { TaskTabComponent } from "./tabs/task/task-tab.component";
import { AddDocumentComponent } from './side-panes/add-document/add-document.component';
import { CustomSwitchToggleModule } from '../shared/custom-switch-toggle/custom-switch-toggle.module';
import { SelectDocumentTypeComponent } from './side-panes/select-document-type/select-document-type.component';
import { JournalTabComponent } from './tabs/journal/journal-tab.component';
import { ViewDocumentComponent } from './side-panes/view-document/view-document.component';
import { CustomSpinnerModule } from '../shared/custom-spinner/custom-spinner.module';
import { RequestDocumentComponent } from './side-panes/request-document/request-document.component';
import { SelectClientComponent } from './side-panes/select-client/select-client.component';
import { CustomSelectorModule } from '../shared/custom-selector/custom-selector.module';
import { ArrangeAppointmentComponent } from "./side-panes/arrange-appointment/arrange-appointment.component";
import { ArrangeAppointmentService } from "./side-panes/arrange-appointment/arrange-appointment.service";
import { SelectAdvisorForAssignmentComponent } from "./side-panes/select-advisor-for-assignment/select-advisor-for-assignment.component";
import { CustomRadioChecksFieldModule } from "../shared/custom-radio-checks-field/custom-radio-checks-field.module";
import { LogContactAttemptComponent } from './side-panes/log-contact-attempt/log-contact-attempt.component';
import { LogContactAttemptService } from "./side-panes/log-contact-attempt/log-contact-attempt.service";
import { DocumentRequestComponent } from './side-panes/document-request/document-request.component';
import { EvergladesSharedMorule } from '../shared/everglades-shared.module';
import { SelectorSidepaneComponent } from "./side-panes/selector-sidepane/selector-sidepane.component";
import { SelectorSidepaneService } from "./side-panes/selector-sidepane/selector-sidepane.service";
import { ApproveComplianceComponent } from "./side-panes/approve-compliance/approve-compliance.component";
import { EditReferenceComponent } from "./side-panes/edit-reference/edit-reference.component";
import { ClientSharedModule } from "../../../../projects/client/src/app/shared/client-shared.module";
import { NotProceedingComponent } from "./side-panes/not-proceeding/not-proceeding.component";
import { RequestFactFindComponent } from "./side-panes/request-fact-find/request-fact-find.component";
import { ReturnFactFindComponent } from "./side-panes/return-fact-find/return-fact-find.component";
import { SelectUserTypeComponent } from "./side-panes/select-user-type/select-user-type.component";
import { SelectUserTypeService } from "./side-panes/select-user-type/select-user-type.service";
import { SelectRequestDocumentTypeComponent } from "./side-panes/select-request-document-type/select-request-document-type.component";
import { SelectDivisionComponent } from "./side-panes/select-division/select-division.component";
import { SelectDateRangeService } from "./side-panes/select-date-range/select-date-range.service";
import { SelectOrAddClientService } from "./side-panes/select-or-add-client/select-or-add-client.service";
import { SendMessageComponent } from "./side-panes/send-message/send-message.component";
import { SelectApplicantComponent } from "./side-panes/select-applicant/select-applicant.component";
import { LinkOffice365Component } from "./pages/link-office-365/link-office-365.component";
import { PdfPreviewComponent } from "./pages/pdf-preview/pdf-preview.component";
import { SuitabilityPdfPreviewComponent } from "./pages/suitability-pdf-preview/suitability-pdf-preview.component";
import { NotProceedingReasonsListComponent } from "./side-panes/not-proceeding-reasons-list/not-proceeding-reasons-list.component";
import { SelectDecliningReasonComponent } from "./side-panes/select-declining-reason/select-declining-reason.component";
import { SelectDecliningReasonService } from "./side-panes/select-declining-reason/select-declining-reason.service";
import { LinkTwentySevenTecComponent } from "./pages/link-twenty-seven-tec/link-twenty-seven-tec.component";
import { SelectTwenty7tecMortgageComponent } from "./side-panes/select-twenty7tec-mortgage/select-twenty7tec-mortgage.component";

const exported = [
  AddTaskComponent,
  TaskTabComponent,
  DocumentsTabComponent,
  AddDocumentComponent,
  SelectDocumentTypeComponent,
  JournalTabComponent,
  ViewDocumentComponent,
  RequestDocumentComponent,
  SelectClientComponent,
  ArrangeAppointmentComponent,
  SelectAdvisorForAssignmentComponent,
  LogContactAttemptComponent,
  DocumentRequestComponent,
  SelectorSidepaneComponent,
  ApproveComplianceComponent,
  EditReferenceComponent,
  NotProceedingComponent,
  RequestFactFindComponent,
  ReturnFactFindComponent,
  SelectUserTypeComponent,
  SelectRequestDocumentTypeComponent,
  SelectDivisionComponent,
  SendMessageComponent,
  SelectApplicantComponent,
  LinkOffice365Component,
  LinkTwentySevenTecComponent,
  PdfPreviewComponent,
  SuitabilityPdfPreviewComponent,
  NotProceedingReasonsListComponent,
  SelectDecliningReasonComponent,
  SelectTwenty7tecMortgageComponent,
];

@NgModule({
  declarations: exported,
  exports: exported,
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CustomTextFieldModule,
    CustomTextAreaModule,
    DatePickerModule,
    CustomButtonModule,
    SelectDateAndTimeModule,
    DocumentsZipButtonModule,
    PaginatorModule,
    ItemsPerPageSelectorModule,
    PaginationSummaryBoxModule,
    HfCheckboxModule,
    CustomSwitchToggleModule,
    CustomSpinnerModule,
    CustomSelectorModule,
    CustomRadioChecksFieldModule,
    EvergladesSharedMorule,
    ClientSharedModule,
  ],
  providers: [
    ArrangeAppointmentService,
    LogContactAttemptService,
    SelectorSidepaneService,
    SelectUserTypeService,
    SelectDateRangeService,
    SelectOrAddClientService,
    SelectDecliningReasonService,
  ]
})
export class SharedLayoutModule { }
