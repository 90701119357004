import { Data, Routes } from "@angular/router";

import { UserPermission } from "../../models";
import { AddProtectionComponent } from "../../shared-layout/side-panes/add-protection/add-protection.component";
import { AddTaskComponent } from "../../shared-layout/side-panes/add-task/add-task.component";
import {
  SelectOrAddClientComponent,
  SelectOrAddClientConfig,
} from "../../shared-layout/side-panes/select-or-add-client/select-or-add-client.component";
import { SelectUserComponent } from "../../shared-layout/side-panes/select-user/select-user.component";
import { ViewJournalEntryComponent } from "../../shared-layout/side-panes/view-journal-entry/view-journal-entry.component";
import { SelectPhonePrefixComponent } from "../introducers/select-phone-prefix/select-phone-prefix.component";
import { DeleteTaskComponent } from "../task/components/delete-task/delete-task.component";
import { ReassignTaskComponent } from "../task/components/reassign-task/reassign-task.component";
import { ViewTaskComponent } from "../task/components/view-task/view-task.component";
import { AddDocumentComponent } from "../../shared-layout/side-panes/add-document/add-document.component";
import { AddInterestedPartyComponent } from "./add-interested-party/add-interested-party.component";
import { AddMortgageComponent } from "./add-mortgage/add-mortgage.component";
import { AddNoteComponent } from "./add-note/add-note.component";
import {
  ApproveComplianceComponent,
  ApproveComplianceConfig,
} from "../../shared-layout/side-panes/approve-compliance/approve-compliance.component";
import { ArrangeAppointmentComponent } from "../../shared-layout/side-panes/arrange-appointment/arrange-appointment.component";
import { AssignCaseProgressionComponent } from "./assign-case-progression/assign-case-progression.component";
import { AssignMortgageComponent } from "./assign-mortgage/assign-mortgage.component";
import { CreateApplicationComponent } from "./create-application/create-application.component";
import { DocumentRequestComponent } from "../../shared-layout/side-panes/document-request/document-request.component";
import { EditReferenceComponent } from "../../shared-layout/side-panes/edit-reference/edit-reference.component";
import { LogContactAttemptComponent } from "../../shared-layout/side-panes/log-contact-attempt/log-contact-attempt.component";
import { MonthlyIncomeComponent } from "./monthly-income/monthly-income.component";
import { MortgageDashboardComponent } from "./mortgage-dashboard/mortgage-dashboard.component";
import { NotProceedingComponent } from "../../shared-layout/side-panes/not-proceeding/not-proceeding.component";
import { MortgagesListComponent } from "./mortgages-list/mortgages-list.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { OfferQualificationComponent } from "./offer-qualification/offer-qualification.component";
import { RequestDocumentComponent } from "../../shared-layout/side-panes/request-document/request-document.component";
import { RequestFactFindComponent } from "../../shared-layout/side-panes/request-fact-find/request-fact-find.component";
import { RequestRequalificationComponent } from "./request-requalification/request-requalification.component";
import { ReturnFactFindComponent } from "../../shared-layout/side-panes/return-fact-find/return-fact-find.component";
import { SelectAdvisorComponent } from "./select-advisor/select-advisor.component";
import { SelectApplicationComponent } from "./select-application/select-application.component";
import { SelectCaseProgressionComponent } from "./select-case-progression/select-case-progression.component";
import { SelectClientComponent } from "../../shared-layout/side-panes/select-client/select-client.component";
import { SelectDateAndTimeComponent } from "./select-date-and-time/select-date-and-time.component";
import { SelectDebtConsolidationComponent } from "./select-debt-consolidation/select-debt-consolidation.component";
import { SelectDocumentTypeComponent } from "../../shared-layout/side-panes/select-document-type/select-document-type.component";
import { SelectHelpToBuyRegionComponent } from "./select-help-to-buy-region/select-help-to-buy-region.component";
import { SelectHelpToBuyStatusComponent } from "./select-help-to-buy-status/select-help-to-buy-status.component";
import { SelectInterestedPartyTypeComponent } from "./select-interested-party-type/select-interested-party-type.component";
import { SelectIntroducerComponent } from "./select-introducer/select-introducer.component";
import { SelectLenderComponent } from "./select-lender/select-lender.component";
import { SelectObjectivesComponent } from "./select-objectives/select-objectives.component";
import { SelectSalesAdvisorComponent } from "./select-sales-advisor/select-sales-advisor.component";
import { SelectSolicitorComponent } from "./select-solicitor/select-solicitor.component";
import { SelectStatusComponent } from "./select-status/select-status.component";
import { SwitchSalesAdvisorComponent } from "../../shared-layout/side-panes/switch-sales-advisor/switch-sales-advisor.component";
import { ToggleNotificationsComponent } from "./toggle-notifications/toggle-notifications.component";
import { UpdateApplicationComponent } from "./update-application/update-application.component";
import { UpdateHelpToBuyComponent } from "./update-help-to-buy/update-help-to-buy.component";
import { ViewDocumentComponent } from "../../shared-layout/side-panes/view-document/view-document.component";
import { SelectAdvisorForAssignmentComponent } from "../../shared-layout/side-panes/select-advisor-for-assignment/select-advisor-for-assignment.component";
import { CreditReportOptComponent } from "./credit-report-opt/credit-report-opt.component";
import { TriggerCreditReportComponent } from "./trigger-credit-report/trigger-credit-report.component";
import { AddRemortgageComponent } from "./add-remortgage/add-remortgage.component";
import { SelectRequestDocumentTypeComponent } from "../../shared-layout/side-panes/select-request-document-type/select-request-document-type.component";
import { SwitchClientCategoryComponent } from "./switch-client-category/switch-client-category.component";
import { SelectDivisionComponent } from "../../shared-layout/side-panes/select-division/select-division.component";
import { MortgageQuickFilter } from "../../services/mortgage-api.service";
import { SelectApplicationLenderComponent } from "./select-application-lender/select-application-lender.component";
import { PinnedNoteComponent } from "../remortgage/sidepanes/pinned-note/pinned-note.component";
import { SendMessageComponent } from "../../shared-layout/side-panes/send-message/send-message.component";
import { SelectApplicantComponent } from "../../shared-layout/side-panes/select-applicant/select-applicant.component";
import { UpdateTargetCompletionDateComponent } from "./update-target-completion-date/update-target-completion-date.component";
import { UpdateTargetExchangeDateComponent } from "./update-target-exchange-date/update-target-exchange-date.component";
import { NewComplianceHandoverComponent } from "./new-compliance-handover/new-compliance-handover.component";
import {
  SelectorSidepaneComponent,
  SelectorSidepaneConfig,
} from "../../shared-layout/side-panes/selector-sidepane/selector-sidepane.component";
import { APPLICANT_SCHEME_NAMES } from "../../../../../projects/client/src/app/fact-find/enums.module";
import {
  FeesToLoanReasonName,
  MortgageDebtConsolidationReasonName,
  MortgageOffsetReasonName,
  MortgageProductTermName,
  RemortgageReasonName,
} from "../../utils/variables.data";
import { MortgageSuitabilityPdfPreviewComponent } from "./mortgage-suitability-pdf-preview/mortgage-suitability-pdf-preview.component";
import { ChangeIntroducerComponent } from "./components/change-introducer/change-introducer.component";
import { SelectPropcoCodeComponent } from "./select-propco-code/select-propco-code.component";
import { NotProceedingReasonsListComponent } from "../../shared-layout/side-panes";
import { SelectDecliningReasonComponent } from "../../shared-layout/side-panes/select-declining-reason/select-declining-reason.component";
import { SelectTwenty7tecMortgageComponent } from "../../shared-layout/side-panes/select-twenty7tec-mortgage/select-twenty7tec-mortgage.component";

export const sharedComplianceRoutes = [
  {
    path: "selectObjectives",
    component: SelectObjectivesComponent,
  },
  {
    path: "selectLender",
    component: SelectLenderComponent,
  },
  {
    path: "selectDateAndTime",
    component: SelectDateAndTimeComponent,
  },
  {
    path: "monthlyIncome",
    component: MonthlyIncomeComponent,
  },
  {
    path: "selectDebtConsolidation",
    component: SelectDebtConsolidationComponent,
  },

  // New Compliance
  {
    path: "selectScheme",
    component: SelectorSidepaneComponent,
    data: {
      config: {
        heading: "Select Scheme",
        key: "selectScheme",
        map: APPLICANT_SCHEME_NAMES,
      } as SelectorSidepaneConfig,
    },
  },
  {
    path: "selectRemoReason",
    component: SelectorSidepaneComponent,
    data: {
      config: {
        heading: "Select Reason",
        key: "selectRemoReason",
        map: RemortgageReasonName,
        multiple: true,
        other: true,
      } as SelectorSidepaneConfig,
    },
  },
  {
    path: "selectDebtConsolidationReason",
    component: SelectorSidepaneComponent,
    data: {
      config: {
        heading: "Select Explanation",
        key: "selectDebtConsolidationReason",
        map: MortgageDebtConsolidationReasonName,
        multiple: true,
        other: true,
      } as SelectorSidepaneConfig,
    },
  },

  // Preferences
  {
    path: "selectProductTerm",
    component: SelectorSidepaneComponent,
    data: {
      config: {
        heading: "Select Term",
        key: "selectProductTerm",
        map: MortgageProductTermName,
      } as SelectorSidepaneConfig,
    },
  },
  {
    path: "selectOffsetReason",
    component: SelectorSidepaneComponent,
    data: {
      config: {
        heading: "Select Reason",
        key: "selectOffsetReason",
        map: MortgageOffsetReasonName,
        multiple: true,
      } as SelectorSidepaneConfig,
    },
  },

  // Recommended
  {
    path: "selectFeesReason",
    component: SelectorSidepaneComponent,
    data: {
      config: {
        heading: "Select Reason",
        key: "selectFeesReason",
        map: FeesToLoanReasonName,
        other: true,
      } as SelectorSidepaneConfig,
    },
  },
  {
    path: "selectDebtConsolidationReasonRecommended",
    component: SelectorSidepaneComponent,
    data: {
      config: {
        heading: "Select Explanation",
        key: "selectDebtConsolidationReason",
        map: MortgageDebtConsolidationReasonName,
        multiple: true,
        other: true,
        // otherHint: `
        // • Leaving some of your debts in place<br>
        // • Switching your mortgage to an alternative deal to release additional income which would service your existing debts<br>
        // • Leaving your existing mortgage in place and using a Secured Loan to repay your debts<br>
        // `
      } as SelectorSidepaneConfig,
    },
  },
  {
    path: "selectPropco",
    component: SelectPropcoCodeComponent,
  },
  {
    path: "selectTwenty7tecMortgage",
    component: SelectTwenty7tecMortgageComponent,
  }
];

export const sharedMortgageDetailRoutes: Routes = [
  {
    path: "assignMortgage",
    component: AssignMortgageComponent,
  },
  {
    path: "addNote",
    component: AddNoteComponent,
    data: {
      hasNotifyIntroducer: true,
    },
    children: [
      {
        path: "selectApplication",
        component: SelectApplicationLenderComponent,
        data: {
          hasNoneOption: true,
        },
      },
    ],
  },
  {
    path: "addDocument",
    component: AddDocumentComponent,
    children: [
      {
        path: "selectDocumentType",
        component: SelectDocumentTypeComponent,
      },
      {
        path: "selectApplication",
        component: SelectApplicationLenderComponent,
        data: {
          hasNoneOption: true,
        },
      },
    ],
  },
  {
    path: "editDocument/:documentId",
    component: ViewDocumentComponent,
    children: [
      {
        path: "selectDocumentType",
        component: SelectDocumentTypeComponent,
      },
    ],
  },
  {
    path: "documentRequest/:documentId",
    component: DocumentRequestComponent,
  },
  {
    path: "viewJournalEntry/:entryId",
    component: ViewJournalEntryComponent,
    data: {
      showIntroducerNotified: true,
      isMortgage: true,
    },
  },
  {
    path: "notProceeding",
    component: NotProceedingComponent,
    children: [
      {
        path: "selectNotProceedingReason",
        component: NotProceedingReasonsListComponent,
      },
      {
        path: "selectDecliningReason",
        component: SelectDecliningReasonComponent,
      },
    ],
  },
  {
    path: "edit",
    component: AddMortgageComponent,
  },
  {
    path: "addContactAttempt",
    component: LogContactAttemptComponent,
    children: [
      {
        path: "selectNotProceedingReason",
        component: NotProceedingReasonsListComponent,
      },
      {
        path: "selectDecliningReason",
        component: SelectDecliningReasonComponent,
      },
      {
        path: "arrangeAppointment",
        component: ArrangeAppointmentComponent,
        children: [
          {
            path: "selectDateAndTime",
            component: SelectDateAndTimeComponent,
          },
          {
            path: "selectPhonePrefix",
            component: SelectPhonePrefixComponent,
          },
          {
            path: "selectHost",
            component: SelectAdvisorForAssignmentComponent,
          },
        ],
      },
    ],
  },
  {
    path: "requestDocument",
    component: RequestDocumentComponent,
    children: [
      {
        path: "selectDocumentType",
        component: SelectRequestDocumentTypeComponent,
      },
      {
        path: "selectClient",
        component: SelectClientComponent,
      },
      {
        path: "selectApplication",
        component: SelectApplicationLenderComponent,
        data: {
          hasNoneOption: true,
        },
      },
    ],
  },
  {
    path: "requestFactFind",
    component: RequestFactFindComponent,
  },
  {
    path: "createApplication",
    component: CreateApplicationComponent,
    children: [
      {
        path: "selectLender",
        component: SelectApplicationLenderComponent,
        data: {
          applicationHandovers: true,
        },
      },
      {
        path: "assignCaseProgression",
        component: AssignCaseProgressionComponent,
      },
      {
        path: "selectSolicitor",
        component: SelectSolicitorComponent,
        children: [
          {
            path: "selectPhonePrefix",
            component: SelectPhonePrefixComponent,
          },
        ],
      },
    ],
  },
  {
    path: "arrangeAppointment",
    component: ArrangeAppointmentComponent,
    children: [
      {
        path: "selectDateAndTime",
        component: SelectDateAndTimeComponent,
      },
      {
        path: "selectPhonePrefix",
        component: SelectPhonePrefixComponent,
      },
      {
        path: "selectHost",
        component: SelectAdvisorForAssignmentComponent,
      },
    ],
  },
  {
    path: "updateApplication/:applicationId",
    component: UpdateApplicationComponent,
    children: [
      {
        path: "selectStatus",
        component: SelectStatusComponent,
      },
      {
        path: "selectNotProceedingReason",
        component: NotProceedingReasonsListComponent,
      },
      {
        path: "selectDecliningReason",
        component: SelectDecliningReasonComponent,
      },
      {
        path: "selectDateAndTime",
        component: SelectDateAndTimeComponent,
      },
      {
        path: "selectPropco",
        component: SelectPropcoCodeComponent,
      },

      {
        path: "selectDecliningReason",
        component: SelectDecliningReasonComponent,
      },
    ],
  },
  {
    path: "assignCaseProgression",
    component: AssignCaseProgressionComponent,
    children: [
      {
        path: "selectApplication",
        component: SelectApplicationComponent,
      },
    ],
  },
  {
    path: "updateHelpToBuy",
    component: UpdateHelpToBuyComponent,
    children: [
      {
        path: "selectStatus",
        component: SelectHelpToBuyStatusComponent,
      },
      {
        path: "selectRegion",
        component: SelectHelpToBuyRegionComponent,
      },
      {
        path: "selectDateAndTime",
        component: SelectDateAndTimeComponent,
      },
    ],
  },
  {
    path: "offer-qualification",
    component: OfferQualificationComponent,
    children: [
      {
        path: "arrangeAppointment",
        component: ArrangeAppointmentComponent,
        children: [
          {
            path: "selectDateAndTime",
            component: SelectDateAndTimeComponent,
          },
          {
            path: "selectPhonePrefix",
            component: SelectPhonePrefixComponent,
          },
          {
            path: "selectHost",
            component: AssignMortgageComponent,
          },
        ],
      },
    ],
  },
  {
    path: "request-requalification",
    component: RequestRequalificationComponent,
  },
  {
    path: "returnFactFind",
    component: ReturnFactFindComponent,
  },
  {
    path: "newComplianceHandover",
    pathMatch: "full",
    redirectTo: "newComplianceHandover/default",
  },
  {
    path: "newComplianceHandover/:handoverId",
    component: NewComplianceHandoverComponent,
    children: sharedComplianceRoutes,
  },

  {
    path: "approveCompliance/:handoverId",
    component: ApproveComplianceComponent,
    data: {
      backPath: "../..",
    } as ApproveComplianceConfig,
  },
  {
    path: "addTask",
    component: AddTaskComponent,
    data: {
      isMortgage: true,
    },
    children: [
      {
        path: "selectUser",
        component: SelectUserComponent,
      },
      {
        path: "selectDateAndTime",
        component: SelectDateAndTimeComponent,
      },
      {
        path: "selectApplication",
        component: SelectApplicationLenderComponent,
        data: {
          hasNoneOption: true,
        },
      },
    ],
  },
  {
    path: "viewTask/:taskId",
    component: ViewTaskComponent,
    children: [
      {
        path: "edit",
        component: AddTaskComponent,
        data: {
          isMortgage: true,
        },
        children: [
          {
            path: "selectUser",
            component: SelectUserComponent,
          },
          {
            path: "selectDateAndTime",
            component: SelectDateAndTimeComponent,
          },
          {
            path: "selectApplication",
            component: SelectApplicationLenderComponent,
            data: {
              hasNoneOption: true,
            },
          },
        ],
      },
      {
        path: "delete",
        component: DeleteTaskComponent,
      },
      {
        path: "reassign",
        component: ReassignTaskComponent,
      },
    ],
  },
  {
    path: "notifications",
    component: NotificationsComponent,
    children: [
      {
        path: "addInterestedParty",
        component: AddInterestedPartyComponent,
        children: [
          {
            path: "selectPhonePrefix",
            component: SelectPhonePrefixComponent,
          },
          {
            path: "selectInterestedPartyType",
            component: SelectInterestedPartyTypeComponent,
          },
        ],
      },
      {
        path: "interestedParty/:interestedPartyId",
        component: AddInterestedPartyComponent,
        children: [
          {
            path: "selectPhonePrefix",
            component: SelectPhonePrefixComponent,
          },
          {
            path: "selectInterestedPartyType",
            component: SelectInterestedPartyTypeComponent,
          },
        ],
      },
      {
        path: "toggleNotifications",
        component: ToggleNotificationsComponent,
      },
    ],
  },
  {
    path: "switchSalesAdvisor",
    component: SwitchSalesAdvisorComponent,
  },
  {
    path: "editLenderReference/:handoverId",
    component: EditReferenceComponent,
  },
  {
    path: "addProtection",
    component: AddProtectionComponent,
    data: {
      permission: <UserPermission[]>["PROTECTION_NEW"],
    },
    children: [
      {
        path: "selectOrAddClient",
        component: SelectOrAddClientComponent,
        children: [
          {
            path: "selectPhonePrefix",
            component: SelectPhonePrefixComponent,
          },
        ],
      },
      {
        path: "selectProtectionAdvisor",
        component: SelectUserComponent,
      },
    ],
  },
  {
    path: "creditReportOpt",
    component: CreditReportOptComponent,
    data: {
      permission: <UserPermission[]>["MORTGAGE_MANAGE"],
    },
  },
  {
    path: "runCreditReport",
    component: TriggerCreditReportComponent,
    data: {
      permission: <UserPermission[]>["MORTGAGE_MANAGE"],
    },
  },
  {
    path: "addRemortgage",
    component: AddRemortgageComponent,
    data: {
      permission: <UserPermission[]>["MORTGAGE_MANAGE"],
    },
    children: [
      {
        path: "selectAdvisor",
        component: SelectAdvisorForAssignmentComponent,
      },
    ],
  },
  {
    path: "switchCategory",
    component: SwitchClientCategoryComponent,
  },
  {
    path: "selectApplicationLender",
    component: SelectApplicationLenderComponent,
  },
  {
    path: "pinned-note",
    component: PinnedNoteComponent,
  },
  {
    path: "sendMessage",
    component: SendMessageComponent,
    data: {
      isMortgage: true,
    },
    children: [
      {
        path: "selectUser",
        component: SelectUserComponent,
      },
      {
        path: "selectApplicant",
        component: SelectApplicantComponent,
      },
    ],
  },
  {
    path: "updateTargetCompletion",
    component: UpdateTargetCompletionDateComponent,
    children: [
      {
        path: "selectDateAndTime",
        component: SelectDateAndTimeComponent,
      },
    ],
  },
  {
    path: "updateTargetExchange",
    component: UpdateTargetExchangeDateComponent,
    children: [
      {
        path: "selectDateAndTime",
        component: SelectDateAndTimeComponent,
      },
    ],
  },
  {
    path: "suitability-letter",
    component: MortgageSuitabilityPdfPreviewComponent,
    // resolve: {
    //   mortgageDashboard: MortgageDashboardDataResolver,
    // },
  },

  {
    path: "changeIntroducer",
    component: ChangeIntroducerComponent,
    children: [
      {
        path: "selectIntroducer",
        component: SelectIntroducerComponent,
      },
      {
        path: "selectDivision",
        component: SelectDivisionComponent,
      },
      {
        path: "selectSalesAdvisor",
        component: SelectSalesAdvisorComponent,
      },
    ],
  },
];

const sharedMortgageRoutes = [
  {
    path: "addMortgage",
    component: AddMortgageComponent,
    data: {
      permission: <UserPermission[]>[
        "MORTGAGE_NEW",
        "MORTGAGE_NEW_INTRODUCER",
        "MORTGAGE_NEW_DIVISION",
        "MORTGAGE_NEW_OWN",
      ],
    },
    children: [
      {
        path: "selectOrAddClient",
        component: SelectOrAddClientComponent,
        children: [
          {
            path: "selectPhonePrefix",
            component: SelectPhonePrefixComponent,
          },
        ],
      },
      {
        path: "selectClient",
        component: SelectOrAddClientComponent,
        children: [
          {
            path: "selectPhonePrefix",
            component: SelectPhonePrefixComponent,
          },
        ],
        data: {
          selectOnly: true,
        } as SelectOrAddClientConfig,
      },
      {
        path: "selectIntroducer",
        component: SelectIntroducerComponent,
      },
      {
        path: "selectDivision",
        component: SelectDivisionComponent,
      },
      {
        path: "selectSalesAdvisor",
        component: SelectSalesAdvisorComponent,
      },
      {
        path: "selectAdvisor",
        component: SelectAdvisorComponent,
      },
      {
        path: "selectHeronAdvisor",
        component: SelectAdvisorForAssignmentComponent,
      },
      {
        path: "selectCaseProgression",
        component: SelectCaseProgressionComponent,
      },
      {
        path: "selectPhonePrefix",
        component: SelectPhonePrefixComponent,
      },
    ],
  },
];

export interface MortgageListRouteData extends Data {
  permission?: UserPermission | UserPermission[];
  backButtonUrl?: string;
  title?: string;
  name?: string;
  mortgageQuickFilter?: MortgageQuickFilter;
  defaultAssignedToMe?: boolean;
}

export const routes: Routes = [
  {
    path: "initial-stage",
    component: MortgagesListComponent,
    data: {
      permission: "MORTGAGES_NOT_SUBMITTED_MENU",
      backButtonUrl: "/app/mortgages/initial-stage",
      mortgageQuickFilter: "INITIAL",
      title: "Mortgages Initial Stage",
      name: "Initial Stage",
      defaultSortBy: "createdDate",
      defaultSortDirection: "DESC",
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },
  {
    path: "active-prospect",
    component: MortgagesListComponent,
    data: {
      permission: "MORTGAGES_NOT_SUBMITTED_MENU",
      backButtonUrl: "/app/mortgages/active-prospect",
      mortgageQuickFilter: "ACTIVE",
      title: "Mortgages Active Prospect",
      name: "Active Prospect",
      defaultAssignedToMe: true,
      defaultSortBy: "createdDate",
      defaultSortDirection: "DESC",
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },
  {
    path: "offered",
    component: MortgagesListComponent,
    data: {
      permission: "MORTGAGE_LIST_ALL",
      backButtonUrl: "/app/mortgages/offered",
      mortgageQuickFilter: "OFFERED",
      title: "Mortgages Offered",
      name: "Offered",
      defaultAssignedToMe: true,
      defaultSortBy: "offerExpiryDate",
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },
  {
    path: "applications-submitted",
    component: MortgagesListComponent,
    data: {
      permission: "MORTGAGE_LIST_ALL",
      backButtonUrl: "/app/mortgages/applications-submitted",
      mortgageQuickFilter: "SUBMITTED",
      title: "Mortgages Applications Submitted",
      name: "Submitted",
      defaultAssignedToMe: true,
      defaultSortBy: "submittedDate",
      defaultSortDirection: "DESC",
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },
  {
    path: "completed",
    component: MortgagesListComponent,
    data: {
      permission: "MORTGAGE_LIST_ALL",
      backButtonUrl: "/app/mortgages/completed",
      mortgageQuickFilter: "COMPLETED",
      title: "Mortgages Completed",
      name: "Completed",
      defaultAssignedToMe: true,
      defaultSortBy: "completionDate",
      defaultSortDirection: "DESC",
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },
  {
    path: "not-proceeding",
    component: MortgagesListComponent,
    data: {
      permission: "MORTGAGE_LIST_ALL",
      backButtonUrl: "/app/mortgages/not-proceeding",
      mortgageQuickFilter: "NOT_PROCEEDING",
      title: "Mortgages Not Proceeding",
      name: "Not Proceeding",
      defaultAssignedToMe: true,
      defaultSortBy: "lastAction",
      defaultSortDirection: "DESC",
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },

  // Introducer routes
  {
    path: "introducer-live",
    component: MortgagesListComponent,
    data: {
      permission: [
        "MORTGAGE_LIST_INTRODUCER",
        "MORTGAGE_LIST_REGION",
        "MORTGAGE_LIST_DIVISION",
      ],
      backButtonUrl: "/app/mortgages/introducer-live",
      mortgageQuickFilter: "INTRODUCER_LIVE",
      title: "Live Applications",
      name: "Live Applications",
      defaultAssignedToMe: true,
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },
  {
    path: "introducer-active",
    component: MortgagesListComponent,
    data: {
      permission: [
        "MORTGAGE_LIST_INTRODUCER",
        "MORTGAGE_LIST_REGION",
        "MORTGAGE_LIST_DIVISION",
      ],
      backButtonUrl: "/app/mortgages/introducer-active",
      mortgageQuickFilter: "INTRODUCER_ACTIVE",
      title: "Active Prospects",
      name: "Active Prospects",
      defaultAssignedToMe: true,
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },
  {
    path: "introducer-completed",
    component: MortgagesListComponent,
    data: {
      permission: [
        "MORTGAGE_LIST_INTRODUCER",
        "MORTGAGE_LIST_REGION",
        "MORTGAGE_LIST_DIVISION",
      ],
      backButtonUrl: "/app/mortgages/introducer-completed",
      mortgageQuickFilter: "INTRODUCER_COMPLETED",
      title: "Completed Applications",
      name: "Completed Applications",
      defaultAssignedToMe: true,
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },
  {
    path: "introducer-not-proceeding",
    component: MortgagesListComponent,
    data: {
      permission: [
        "MORTGAGE_LIST_INTRODUCER",
        "MORTGAGE_LIST_REGION",
        "MORTGAGE_LIST_DIVISION",
      ],
      backButtonUrl: "/app/mortgages/introducer-not-proceeding",
      mortgageQuickFilter: "INTRODUCER_NOT_PROCEEDING",
      title: "Not Proceeding",
      name: "Not Proceeding",
      defaultAssignedToMe: true,
    } as MortgageListRouteData,
    children: sharedMortgageRoutes,
  },

  //  {
  //    path: 'active',
  //    component: MortgagesListComponent,
  //    data: {
  //      permission: "MORTGAGE_LIST_INTRODUCER",
  //      backButtonUrl: "/app/mortgages/active",
  //      mortgageQuickFilter: "INTRODUCER_ACTIVE",
  //      title: "Mortgages",
  //      name: "Active",
  //    } as MortgageListRouteData,
  //    children: sharedMortgageRoutes
  //  },
  //  {
  //    path: 'my-referrals',
  //    component: MortgagesListComponent,
  //    data: {
  //      permission: "MORTGAGES_REFERRALS_MENU",
  //      backButtonUrl: "/app/mortgages/my-referrals",
  //      mortgageQuickFilter: "INTRODUCER_REFERRED",
  //      title: "Mortgages",
  //      name: "My Referrals",
  //    } as MortgageListRouteData,
  //    children: sharedMortgageRoutes
  //  },
  //  {
  //    path: 'archive',
  //    component: MortgagesListComponent,
  //    data: {
  //      permission: "MORTGAGES_ARCHIVE_MENU",
  //      backButtonUrl: "/app/mortgages/archive",
  //      mortgageQuickFilter: "INTRODUCER_ARCHIVE",
  //      title: "Mortgages",
  //      name: "Archived",
  //    } as MortgageListRouteData,
  //    children: sharedMortgageRoutes
  //  },

  {
    path: "",
    pathMatch: "full",
    redirectTo: "initial-stage",
  },
  {
    path: ":id",
    component: MortgageDashboardComponent,
    data: {
      permission: <UserPermission[]>[
        "MORTGAGE_VIEW_ALL",
        "MORTGAGE_VIEW_INTRODUCER",
        "MORTGAGE_VIEW_DIVISION",
        "MORTGAGE_VIEW_REGION",
      ],
      title: "%client% - Mortgages",
    },
    children: sharedMortgageDetailRoutes,
  },
];
