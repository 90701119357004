<div class="ev-details-container">
  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Objective</div>
      <div class="ev-details-entry-value">{{ data.otherObjective || MORTGAGE_OBJECTIVE_NAME.get(data.objective) }}</div>
    </div>

    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Scheme</div>
      <div class="ev-details-entry-value">{{ APPLICANT_SCHEME_NAMES_ALL.get(data.scheme) }}</div>
    </div>

    <div *ngIf="data.remortgageReason" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for remortgage</div>
      <div class="ev-details-entry-value">{{ valueOther(data.remortgageReason, RemortgageReasonName, data.otherRemortgageReason) }}</div>
    </div>
  </div>

  <div class="ev-details-column">
    <div class="ev-details-entry">
      <div class="ev-details-entry-label">Debt consolidation</div>
      <div class="ev-details-entry-value">{{ yesno(data.debtConsolidation) }}</div>
    </div>

    <div *ngIf="data.debtConsolidation" class="ev-details-entry">
      <div class="ev-details-entry-label">Amount of new loan used to repay existing debt</div>
      <div class="ev-details-entry-value">{{ data.amountUsedToRepay | customCurrency:'GBP':true:'1.0-2' }}</div>
    </div>

    <div *ngIf="data.debtConsolidation" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for debt consolidation</div>
      <div class="ev-details-entry-value">{{ valueOther(data.debtConsolidationReason, MortgageDebtConsolidationReasonName, data.otherDebtConsolidationReason) }}</div>
    </div>
  </div>

  <div class="ev-details-column">
    <div *ngIf="data.debtConsolidation" class="ev-details-entry">
      <div class="ev-details-entry-label">Debt consolidation notes</div>
      <div class="ev-details-entry-value">{{ data.debtConsolidationNote }}</div>
    </div>

    <div *ngIf="data.increasedBorrowingReason" class="ev-details-entry">
      <div class="ev-details-entry-label">Reason for increasing borrowing</div>
      <div class="ev-details-entry-value">{{ data.increasedBorrowingReason }}</div>
    </div>
  </div>
</div>
