<hf-category-details>
  <div class="cd-details-container">
    <div class="cd-details-column">
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Total credit limit</div>
        <div class="cd-details-entry-value">{{ report.totalCreditLimit | customCurrency:'GBP':true:'1.0-2' }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Total credit commitments</div>
        <div class="cd-details-entry-value">{{ report.totalCreditCommitments | customCurrency:'GBP':true:'1.0-2' }}</div>
      </div>
    </div>
    <div class="cd-details-column">
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Total credit limit used</div>
        <div class="cd-details-entry-value">{{ report.totalCreditLimitUsed | customCurrency:'GBP':true:'1.0-2' }}</div>
      </div>
      <div class="cd-details-entry">
        <div class="cd-details-entry-label">Credit used percentage</div>
        <div class="cd-details-entry-value">{{ report.creditUsedPercentage }} %</div>
      </div>
    </div>
  </div>

  <div class="cd-header cd-h1">
    Accounts
  </div>

  <hf-account-details [category]="report.creditCards"></hf-account-details>
</hf-category-details>
