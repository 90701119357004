<div (click)="onBack()" class="overlay"  [@fadeAnimation]></div>

<div class="sidepane-content cols-2">
  <div class="flex-space-between-layout template-header">
    <h1>{{ isEdit ? 'Edit Client Sheet' : 'Compliance Handover' }}</h1>

    <hf-custom-button type="button" class="button-plain-icon" icon="close" iconPos="center" (onClick)="onBack()">
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner *ngIf="isLoading" sidepaneLoading="true"></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <div class="form-section bottom-border">
        <div class="form-section__title">General Notes</div>
        <div class="form-section__content">
          <div class="form-section__column">
            <div class="field">
              <label>Current Situation</label>
              <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'currentSituation'" fieldName="currentSituation"></hf-textarea>
            </div>

          </div>

          <div class="form-section__column">
            <div class="field">
              <hf-selector-link label="Objectives"
                                placeholder="Select Objectives"
                                [value]="form.get('otherObjective').value || MORTGAGE_OBJECTIVE_NAME.get(form.get('objective').value)"
                                (click)="onSelectObjectivesClicked()">
              </hf-selector-link>
            </div>
          </div>
        </div>
      </div>

      <div class="form-section bottom-border">
        <div class="form-section__title">Mortgage Notes</div>
        <div class="form-section__content">
          <div class="form-section__column">
            <div class="field">
              <label>Client mortgage need</label>
              <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'clientMortgageNeed'" fieldName="clientMortgageNeed"></hf-textarea>
            </div>

            <div class="field">
              <hf-card-with-add-button [hasValue]="form.get('netMonthlyIncomes').value" (click)="onMonthlyIncomeClicked()">Add Net Monthly Income
                <ng-template #hasValueTemplate>
                  <div class="net-income-card">
                    <div *ngFor="let income of form.get('netMonthlyIncomes').value" class="net-income-card__applicant">
                      {{getApplicantName(income.applicantId)}} - {{income.amount|currency:'GBP':undefined}}
                    </div>
                  </div>
                </ng-template>
              </hf-card-with-add-button>
            </div>

            <div class="field">
            	<label>Explain income calculation</label>
            	<hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'incomeCalculationExplanation'" fieldName="incomeCalculationExplanation"></hf-textarea>
            </div>

            <div class="field">
              <label>Add fees to loan</label>
              <hfc-yes-no-checkbox formControlName="feesToLoan"></hfc-yes-no-checkbox>
            </div>

            <div class="field">
              <label>Will the mortgage take the client beyond state retirement age</label>
              <hfc-yes-no-checkbox formControlName="mortgageBeyondRetirement"></hfc-yes-no-checkbox>
            </div>

            <div class="field">
              <label>Key Facts Illustration</label>
              <hf-fileuploader controlName="kfi"
                               [group]="form"
                               [fileControl]="form.value['kfi']">
              </hf-fileuploader>
            </div>

            <div class="field">
              <label>Evidence of Recommendation</label>
              <hf-fileuploader controlName="recommendationEvidence"
                               [group]="form"
                               [fileControl]="form.value['recommendationEvidence']">
              </hf-fileuploader>
            </div>
          </div>

          <div class="form-section__column">
            <div class="field">
              <label>Explain choice of lender and term</label>
              <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'choiceOfLenderExplanation'" fieldName="choiceOfLenderExplanation"></hf-textarea>
            </div>

            <div class="field">
              <hf-checkbox label="Debt Consolidation" formControlName="debtConsolidation">
              </hf-checkbox>
            </div>

            <div *ngIf="this.form.get('debtConsolidationExplanation').enabled" class="field">
              <hf-selector-link label="Explain debt consolidation"
                                placeholder="Select Explanation"
                                [value]="form.get('otherDebtConsolidationExplanation').value || DEBT_CONSOLIDATION_EXPLANATION_NAME.get(form.get('debtConsolidationExplanation').value)"
                                (click)="onSelectDebtConsolidationClicked()">
              </hf-selector-link>
            </div>

            <div class="field">
              <hf-checkbox label="Interest Only" formControlName="interestOnly">
              </hf-checkbox>
            </div>

            <div *ngIf="form.get('interestOnlyExplanation').enabled" class="field">
              <label>Explain interest only</label>
              <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'interestOnlyExplanation'" fieldName="interestOnlyExplanation"></hf-textarea>
            </div>

            <div class="field">
              <hf-checkbox label="Any known income changes?" formControlName="knownIncomeChanges">
              </hf-checkbox>
            </div>

            <div *ngIf="form.get('knownIncomeChangesExplanation').enabled" class="field">
              <label>Explain known income changes</label>
              <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'knownIncomeChangesExplanation'" fieldName="knownIncomeChangesExplanation"></hf-textarea>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isCurrentVisible" class="form-section bottom-border">
        <div class="form-section__title">Current Rate</div>
          <div class="form-section__content">
          <div class="form-section__column">
            <div class="field">
              <label>Mortgage Term</label>
              <div class="years-months" formGroupName="currentMortgageTerm">
                <hfc-text-input sufix="years" type="number" formControlName="years"></hfc-text-input>
                <hfc-text-input sufix="months" type="number" formControlName="months"></hfc-text-input>
              </div>
            </div>

            <div class="field">
              <label>Rate</label>
              <hfc-text-input sufix="%" mask="separator.2" formControlName="currentRate"></hfc-text-input>
            </div>
          </div>

          <div class="form-section__column">
            <div class="field field-checkbox-list">
              <label>Mortgage Type</label>
              <hfc-checkbox-list [map]="MORTGAGE_PRODUCT_DATA_TYPE_NAME" formControlName="currentType"></hfc-checkbox-list>
            </div>
          </div>
        </div>
      </div>

      <div class="form-section bottom-border">
        <div class="form-section__title">{{ isCurrentVisible ? 'New Product Data' : 'Product Data' }}</div>
        <div class="form-section__content">
          <div class="form-section__column">
            <div class="field">
              <hf-selector-link label="Lender"
                                placeholder="Select Lender"
                                [value]="form.get('lender').value && form.get('lender').value.name"
                                (click)="onSelectLenderClicked()">
              </hf-selector-link>
            </div>

            <div class="field">
              <label>Loan Amount</label>
              <hfc-text-input prefix="£" [currency]="true" formControlName="loanAmount"></hfc-text-input>
            </div>

            <div class="field">
              <label>Mortgage Term</label>
              <div class="years-months" formGroupName="mortgageTerm">
                <hfc-text-input sufix="years" type="number" formControlName="years"></hfc-text-input>
                <hfc-text-input sufix="months" type="number" formControlName="months"></hfc-text-input>
              </div>
            </div>

            <div class="field">
              <label>Rate</label>
              <hfc-text-input sufix="%" mask="separator.2" formControlName="rate"></hfc-text-input>
            </div>

            <div class="field">
              <label>Product Code</label>
              <hfc-text-input formControlName="productCode"></hfc-text-input>
            </div>
          </div>

          <div class="form-section__column">
            <div class="field field-checkbox-list">
              <label>Mortgage Type</label>
              <hfc-checkbox-list [map]="MORTGAGE_PRODUCT_DATA_TYPE_NAME" formControlName="type"></hfc-checkbox-list>
            </div>

            <div class="field field-checkbox-list">
              <label>Product End Date</label>
              <hfc-checkbox-list [map]="MORTGAGE_PRODUCT_END_TYPE_NAME" formControlName="endDateType"></hfc-checkbox-list>
            </div>

            <div *ngIf="form.get('endDate').enabled" class="field">
              <label>Select Product End Date</label>
              <hf-date-picker formControlName="endDate" propertyName="endDate" [onlyDate]="true" class="desktop-date-time"></hf-date-picker>

              <div class="flex-space-between-layout native-mobile-date-time">
                <hf-custom-native-mobile-datetime formControlName="endDate" onlyDate="true" [invalid]="form.get('endDate').errors?.minDate" class="native-mobile-date-time">
                </hf-custom-native-mobile-datetime>
              </div>
            </div>
            <div *ngIf="form.get('endYearsMonths').enabled" class="field">
              <label>Specify End Date</label>
              <div class="years-months" formGroupName="endYearsMonths">
                <hfc-text-input sufix="years" type="number" formControlName="years"></hfc-text-input>
                <hfc-text-input sufix="months" type="number" formControlName="months"></hfc-text-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="form.get('reasonForChange').enabled" class="form-section">
        <div class="form-section__content">
          <div class="form-section__column form-section__column-full-width">
            <div class="field">
              <label>Reason for Change</label>
              <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'reasonForChange'" fieldName="reasonForChange"></hf-textarea>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hfc-button type="primary" [disabled]="form.invalid" [isLoading]="isSubmitting" (click)="onSubmit()">Hand Over</hfc-button>
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
