import { Pipe, PipeTransform } from "@angular/core";

/**
 * this pipe formats the currency without rounding the input off,
 *  which is the case with angular default currency pipe
 */
@Pipe({
  name: "customCurrency",
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currencyCode: string = "GBP",
    symbolDisplay: boolean = true,
    digits: string = "1.2-2"
  ): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    // Parse the digits string to get the min and max decimal places
    const [minDecimal, maxDecimal] = digits.split(".").length > 0 ? digits.split(".")[1]
      .split("-")
      .map((num) => parseInt(num, 10)) : [0, 0];

    // Convert the number to a string and truncate manually without rounding
    let [integerPart, decimalPart] = value.toString().split(".");

    if (decimalPart && decimalPart.length > maxDecimal) {
      decimalPart = decimalPart.slice(0, maxDecimal);
    }

    // Combine integer and truncated decimal parts
    const truncatedValueStr = decimalPart
      ? `${integerPart}.${decimalPart}`
      : integerPart;

    // Convert the truncated string back to a number
    const truncatedValue = parseFloat(truncatedValueStr);

    // Format the number as currency
    const formattedValue = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: minDecimal,
      maximumFractionDigits: maxDecimal,
    }).format(truncatedValue);

    // Optionally remove the currency symbol
    if (!symbolDisplay) {
      return formattedValue.replace(/[^0-9.-]+/g, "");
    }

    return formattedValue;
  }
}
