<div class="header">
  <div class="heading">
    Income
    <div class="applicant">{{ getApplicantName(applicantData) }}</div>

    <div *ngIf="config?.updatedBy" class="saved-by">
      Saved by {{ config.updatedBy.fullName }} at {{config?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="config.buttonType" [type]="config.buttonType" [isLoading]="config.buttonIsLoading" size="small" (click)="buttonClick.next(config)">{{ config.buttonLabel }}</hfc-button>
</div>

<div *ngIf="config.returnComment" class="returnComment">
  {{config.returnComment}}
  <div class="lastUpdated">{{config.lastUpdated | date:'d MMM y, HH:mm'}}</div>
</div>

<div *ngIf="config.stepStatus == 'NOT_STARTED'" class="notReady">
  Waiting for applicant to complete
</div>

<ng-container *ngIf="config.stepStatus !== 'NOT_STARTED'">
  <div class="content">
    <div *ngIf="applicantData.employmentDetails?.nationalInsuranceNumber" class="column">
      <div class="record">
        <span class="label">National Insurance Number</span>
        <span class="value">{{ applicantData.employmentDetails.nationalInsuranceNumber }}</span>
      </div>
    </div>

    <div *ngIf="applicantData.employmentDetails?.retirementAge" class="column">
      <div class="record">
        <span class="label">Expected Retirement Age</span>
        <span class="value">{{ applicantData.employmentDetails.retirementAge }}</span>
      </div>
    </div>
  </div>

  <div *ngFor="let employment of applicantData.employments; let i=index" class="content repeated">
    <div class="header-2">
      {{ EMPLOYMENT_STATUS_NAME.get(employment.employmentStatus) + ' since ' + (employment.startDate | date:'d MMM y') + (employment.endDate ? ' till ' + (employment.endDate | date:'d MMM y') : '') }}
    </div>

    <ng-container *ngIf="employment.employerName">
      <div class="column">
        <div class="record">
          <span class="label">{{ employment.employmentStatus === 'EMPLOYED' ? 'Employer Name' : 'Business Name' }}</span>
          <span class="value">{{ employment.employerName }}</span>
        </div>
        <div class="record">
          <span class="label">Occupation</span>
          <span class="value">{{ employment.occupation }}</span>
        </div>
        <div class="record">
          <span class="label">Salary</span>
          <span class="value">{{ employment.salary | customCurrency:"GBP":undefined:"1.0-2" }}</span>
        </div>

        <div *ngIf="employment.guaranteedOvertime" class="record">
          <span class="label">Annual Guaranteed Overtime</span>
          <span class="value">{{ employment.guaranteedOvertime | customCurrency:"GBP":undefined:"1.0-2" }}</span>
        </div>
        <div *ngIf="employment.notGuaranteedOvertime" class="record">
          <span class="label">Annual Not Guaranteed Overtime</span>
          <span class="value">{{ employment.notGuaranteedOvertime | customCurrency:"GBP":undefined:"1.0-2" }}</span>
        </div>
        <div *ngIf="employment.regularBonus" class="record">
          <span class="label">Annual Bonus</span>
          <span class="value">{{ employment.regularBonus | customCurrency:"GBP":undefined:"1.0-2" }}</span>
        </div>
        <div *ngIf="employment.regularCommission" class="record">
          <span class="label">Annual Commission</span>
          <span class="value">{{ employment.regularCommission | customCurrency:"GBP":undefined:"1.0-2" }}</span>
        </div>
        <div *ngIf="employment.carAllowance" class="record">
          <span class="label">Annual Car Allowance</span>
          <span class="value">{{ employment.carAllowance | customCurrency:"GBP":undefined:"1.0-2" }}</span>
        </div>
        <div *ngIf="employment.largeTownAllowance" class="record">
          <span class="label">Annual Large Town Allowance</span>
          <span class="value">{{ employment.largeTownAllowance | customCurrency:"GBP":undefined:"1.0-2" }}</span>
        </div>
        <div *ngIf="employment.otherIncome" class="record">
          <span class="label">Other Income Annual</span>
          <span class="value">{{ employment.otherIncomeAmount | customCurrency:"GBP":undefined:"1.0-2" }}</span>
        </div>
        <div *ngIf="employment.otherIncome" class="record">
          <span class="label">Other Income Description</span>
          <span class="value">{{ employment.otherDescription }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="employment.employerAddress">
      <div class="column">
        <div class="record">
          <span class="label">House</span>
          <span class="value">{{ employment.employerAddress.house }}</span>
        </div>
        <div class="record">
          <span class="label">Street</span>
          <span class="value">{{ employment.employerAddress.street }}</span>
        </div>
        <div *ngIf="employment.employerAddress.line2" class="record">
          <span class="label">Address Line 2</span>
          <span class="value">{{ employment.employerAddress.line2 }}</span>
        </div>
        <div class="record">
          <span class="label">City</span>
          <span class="value">{{ employment.employerAddress.city }}</span>
        </div>
        <div class="record">
          <span class="label">Country</span>
          <span class="value">{{ employment.employerAddress.country }}</span>
        </div>
        <div class="record">
          <span class="label">Postcode</span>
          <span class="value">{{ employment.employerAddress.postcode }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="employment.accountantName">
      <div class="column">
        <div class="record">
          <span class="label">Is Limited Company?</span>
          <span class="value">{{ employment.limitedCompany ? 'Yes' : 'No'}}</span>
        </div>

        <div *ngIf="employment.limitedCompany" class="record">
          <span class="label">Your Percentage Share </span>
          <span class="value">{{ employment.sharePercentage }}%</span>
        </div>
        <div *ngIf="employment.limitedCompany" class="record">
          <span class="label">Annual Dividends</span>
          <span class="value">{{ employment.annualDividends | customCurrency:"GBP":undefined:"1.0-2" }}</span>
        </div>

        <div class="record">
          <span class="label">No. Years Trading</span>
          <span class="value">{{ employment.yearsTrading }}</span>
        </div>
      </div>

      <div class="column">
        <div class="record">
          <span class="label">Accountant Name</span>
          <span class="value">{{ employment.accountantName }}</span>
        </div>
        <div class="record">
          <span class="label">Accountant Email</span>
          <span class="value">{{ employment.accountantEmail }}</span>
        </div>
        <div class="record">
          <span class="label">Accountant Phone</span>
          <span class="value">{{ employment.accountantPhonePrefix}} {{employment.accountantPhone }}</span>
        </div>
        <div class="record">
          <span class="label">No. Years of Accounts</span>
          <span class="value">{{ employment.yearsAccounts }}</span>
        </div>
      </div>
         
      <!-- Twenty7tec related fields -->
      <div class="column">
        <div *ngIf="employment.currentYearProjectedSalary" class="record">
          <span class="label">Current Year Projected Salary</span>
          <span class="value">{{ employment.currentYearProjectedSalary }}</span>
        </div>
        <div *ngIf="employment.currentYearProjectedDividends" class="record">
          <span class="label">Current Year Projected Dividends</span>
          <span class="value">{{ employment.currentYearProjectedDividends }}</span>
        </div>
        <div *ngIf="employment.currentYearProjectedNetProfit" class="record">
          <span class="label">Current Year Projected Net Profit</span>
          <span class="value">{{ employment.currentYearProjectedNetProfit }}</span>
        </div>
        <div *ngIf="employment.lastYearAnnualSalary" class="record">
          <span class="label">Last Year’s Annual Salary</span>
          <span class="value">{{ employment.lastYearAnnualSalary }}</span>
        </div>
        <div *ngIf="employment.lastYearAnnualDividends" class="record">
          <span class="label">Last Year’s Annual Dividends</span>
          <span class="value">{{ employment.lastYearAnnualDividends }}</span>
        </div>
        <div *ngIf="employment.lastYearNetProfit" class="record">
          <span class="label">Last Year’s Net Profit</span>
          <span class="value">{{ employment.lastYearNetProfit }}</span>
        </div>
      </div>
      <div class="column">
        <div *ngIf="employment.lastButOneYearAnnualSalary" class="record">
          <span class="label">Last But 1 Year’s Annual Salary</span>
          <span class="value">{{ employment.lastButOneYearAnnualSalary }}</span>
        </div>
        <div *ngIf="employment.lastButOneYearDividends" class="record">
          <span class="label">Last But 1 Year’s Annual Dividends</span>
          <span class="value">{{ employment.lastButOneYearDividends }}</span>
        </div>
        <div *ngIf="employment.lastButOneYearNetProfit" class="record">
          <span class="label">Last But 1 Year’s Net Profit</span>
          <span class="value">{{ employment.lastButOneYearNetProfit }}</span>
        </div>
        <div *ngIf="employment.lastButTwoYearsAnnualSalary" class="record">
          <span class="label">Last But 2 Year’s Annual Salary</span>
          <span class="value">{{ employment.lastButTwoYearsAnnualSalary }}</span>
        </div>
        <div *ngIf="employment.lastButTwoYearsDividends" class="record">
          <span class="label">Last But 2 Year’s Annual Dividends</span>
          <span class="value">{{ employment.lastButTwoYearsDividends }}</span>
        </div>
        <div *ngIf="employment.lastButTwoYearsNetProfit" class="record">
          <span class="label">Last But 2 Year’s Net Profit</span>
          <span class="value">{{ employment.lastButTwoYearsNetProfit }}</span>
        </div>
      </div>

    </ng-container>
  </div>

  <div *ngFor="let income of applicantData.incomes" class="content repeated">
    <div class="header-2">
      {{ (income.otherIncomeType || INCOME_TYPE_NAME.get(income.incomeType)) + ' since ' + (income.incomeStartDate | date:'d MMM y') }}
    </div>
    <div class="column">
      <div class="record">
        <span class="label">Annual Amount</span>
        <span class="value">{{ income.amount | customCurrency:"GBP":undefined:"1.0-2" }}</span>
      </div>
    </div>
  </div>
</ng-container>
