import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as moment from "moment";

import { JournalBasic } from "./mortgage-api.service";
import { ApiService } from "./api.service";
import { ListResult, TableResult } from "./api-models";
import { toLocalDateISO } from "../../../../projects/client/src/app/shared/functions/to-local-date-iso";
import {
  ProtectionCase,
  ProtectionDashboardDTO,
  ProtectionCaseNew,
  UserWithContactDTO,
  ContactAttemptDTO,
  ProviderDTO,
  ProtectionApplicationViewDTO,
  ProtectionApplicationsListDTO,
  RefreshApplicationListDTO,
  PrerequisitesDTO,
  ApplicantPrerequisitesAddDTO,
  ProtectionApplicationStateInfoDTO,
  ProtectionApplicationStateChangeDTO,
  IncomeBenefitHandoverDTO,
  PersonalBenefitHandoverDTO,
  MortgageBenefitHandoverDTO,
  MortgageBenefitHandoverViewDTO,
  PersonalBenefitHandoverViewDTO,
  IncomeBenefitHandoverViewDTO,
  ProtectionClientSheetDTO,
  ProviderReferenceDTO,
  ProtectionImportedDataDTO,
  ClientNeed,
  SuitabilityLetterDTO,
  ProtectionApplicationDTO,
  ProtectionComplianceHandoverViewDTO,
  ProtectionHandoverGeneralDTO,
} from "../models";
import { NotProceedingReasonsDTO } from "./not-proceeding-reasons.service";
import { JournalWithReasonDTO } from "../models/journal-with-reason.dto";

export type ProtectionQuickFilter =
  | "INITIAL"
  | "ACTIVE"
  | "SUBMITTED"
  | "ON_RISK"
  | "REPLACED"
  | "NOT_PROCEEDING";

@Injectable()
export class  ProtectionApiService {
  private readonly API_NAME: string = "protection";

  constructor(private readonly apiService: ApiService) {}

  public listProtectionCases(params: {
    [key: string]: any;
  }): Observable<TableResult<ProtectionCase>> {
    return this.apiService.get(this.API_NAME, "listProtectionCases", {
      params,
    });
  }

  public getDashboardData(
    protectionCaseId: string
  ): Observable<ProtectionDashboardDTO> {
    const params = { protectionCaseId };

    return this.apiService.get(this.API_NAME, "getDashboardData", { params });
  }

  public async addProtectionCase(
    protectionCase: ProtectionCaseNew
  ): Promise<ProtectionCase> {
    return this.apiService
      .post(this.API_NAME, "addProtectionCase", protectionCase)
      .toPromise();
  }

  public async replaceProtectionCase(
    protectionCaseId: string,
    protectionCase: ProtectionDashboardDTO
  ): Promise<ProtectionCase> {
    return this.apiService
      .post(this.API_NAME, `replaceProtectionCase?protectionCaseId=${protectionCaseId}`, protectionCase)
      .toPromise();
  }

  public async getProtectionCaseApplicants(
    protectionCaseId: string
  ): Promise<UserWithContactDTO[]> {
    const method = "getProtectionCaseApplicants";
    const params = { protectionCaseId };

    const result = await this.apiService
      .get(this.API_NAME, method, { params })
      .toPromise<ListResult<UserWithContactDTO>>();
    return result.items;
  }

  public addContactAttempt(contactAttemptDTO: ContactAttemptDTO, id: string) {
    if (contactAttemptDTO.appointment)
      contactAttemptDTO.appointment.appointmentDate = toLocalDateISO(
        contactAttemptDTO.appointment.appointmentDate,
        false
      );
    return this.apiService
      .post(
        this.API_NAME,
        "addContactAttempt?protectionCaseId=" + id,
        contactAttemptDTO
      )
      .toPromise();
  }

  public async getAllProviders(): Promise<ProviderDTO[]> {
    const result = await this.apiService
      .get(this.API_NAME, "getAllProviders")
      .toPromise<ListResult<ProviderDTO>>();

    return result.items;
  }

  public async addManualApplication(
    protectionCaseId: string,
    protectionApplication: ProtectionApplicationDTO
  ): Promise<void> {
    const params = { protectionCaseId };

    this.prepareProtectionApplicationDTO(protectionApplication);

    return this.apiService
      .post(this.API_NAME, "addManualApplication", protectionApplication, {
        params,
      })
      .toPromise();
  }

  public async editManualApplication(
    protectionApplicationId: string,
    protectionApplication: ProtectionApplicationDTO
  ): Promise<void> {
    const params = { protectionApplicationId };

    this.prepareProtectionApplicationDTO(protectionApplication);

    return this.apiService
      .post(this.API_NAME, "editManualApplication", protectionApplication, {
        params,
      })
      .toPromise();
  }

  public async viewApplication(
    protectionApplicationId: string
  ): Promise<ProtectionApplicationViewDTO> {
    const params = { protectionApplicationId };
    const application = await this.apiService
      .get(this.API_NAME, "viewApplication", { params })
      .toPromise<ProtectionApplicationViewDTO>();

    if (application.expiryDate) {
      application.expiryDate = moment(application.expiryDate).toDate();
    }

    return application;
  }

  public async getNotProceedingReasons(
    protectionCaseId: string
  ): Promise<NotProceedingReasonsDTO> {
    const params = { protectionCaseId };

    return this.apiService
      .get(this.API_NAME, "getNotProceedingReasons", { params })
      .toPromise();
  }

  public async deleteApplication(
    protectionApplicationId: string
  ): Promise<void> {
    const params = { protectionApplicationId };

    return this.apiService
      .delete(this.API_NAME, "deleteApplication", { params })
      .toPromise();
  }

  public getApplicationList(
    protectionCaseId: string
  ): Observable<ProtectionApplicationsListDTO> {
    const params = { protectionCaseId };
    return this.apiService.get(this.API_NAME, "getApplicationList", { params });
  }

  public refreshApplicationList(
    protectionCaseId: string
  ): Observable<RefreshApplicationListDTO> {
    const params = { protectionCaseId };
    return this.apiService.get(this.API_NAME, "refreshApplicationList", {
      params,
    });
  }

  public async getApplicantsPrerequisites(
    protectionCaseId: string
  ): Promise<PrerequisitesDTO> {
    const params = { protectionCaseId };

    return this.apiService
      .get(this.API_NAME, "getApplicantsPrerequisites", { params })
      .toPromise();
  }

  public async viewApplicantPrerequisites(
    protectionCaseId: string,
    clientId: string
  ): Promise<ApplicantPrerequisitesAddDTO> {
    const params = { protectionCaseId, clientId };
    const application = await this.apiService
      .get(this.API_NAME, "viewApplicantPrerequisites", { params })
      .toPromise<ApplicantPrerequisitesAddDTO>();

    if (application.dateOfBirth) {
      application.dateOfBirth = moment(application.dateOfBirth).toDate();
    }

    return application;
  }

  public async addApplicantWithPrerequisites(
    protectionCaseId: string,
    applicantPrerequisitesAdd: ApplicantPrerequisitesAddDTO
  ): Promise<void> {
    const params = { protectionCaseId };

    applicantPrerequisitesAdd.dateOfBirth = toLocalDateISO(
      applicantPrerequisitesAdd.dateOfBirth
    ) as any;

    return this.apiService
      .post(
        this.API_NAME,
        "addApplicantWithPrerequisites",
        applicantPrerequisitesAdd,
        { params }
      )
      .toPromise();
  }

  public async editApplicantPrerequisites(
    protectionCaseId: string,
    clientId: string,
    applicantPrerequisitesAdd: ApplicantPrerequisitesAddDTO
  ): Promise<void> {
    const params = { protectionCaseId, clientId };

    applicantPrerequisitesAdd.dateOfBirth = toLocalDateISO(
      applicantPrerequisitesAdd.dateOfBirth
    ) as any;

    return this.apiService
      .post(
        this.API_NAME,
        "editApplicantPrerequisites",
        applicantPrerequisitesAdd,
        { params }
      )
      .toPromise();
  }

  public async deleteApplicant(
    protectionCaseId: string,
    clientId: string
  ): Promise<void> {
    const params = { protectionCaseId, clientId };

    return this.apiService
      .delete(this.API_NAME, "deleteApplicant", { params })
      .toPromise();
  }

  public async getApplicationStateInfo(
    protectionApplicationId: string
  ): Promise<ProtectionApplicationStateInfoDTO> {
    const params = { protectionApplicationId };

    return this.apiService
      .get(this.API_NAME, "getApplicationStateInfo", { params })
      .toPromise();
  }

  public updateApplicationState(
    protectionApplicationId: string,
    stateChange: ProtectionApplicationStateChangeDTO
  ): Promise<void> {
    const params = { protectionApplicationId };

    if (stateChange.termsExpiryDate) {
      stateChange.termsExpiryDate = toLocalDateISO(
        stateChange.termsExpiryDate
      ) as any;
    }
    if (stateChange.dateOnRisk) {
      stateChange.dateOnRisk = toLocalDateISO(stateChange.dateOnRisk) as any;
    }

    return this.apiService
      .post(this.API_NAME, "updateApplicationState", stateChange, { params })
      .toPromise();
  }

  public saveIncomeBenefitHandover(
    protectionCaseId: string,
    benefitId: string,
    incomeBenefit: IncomeBenefitHandoverDTO
  ): Promise<void> {
    const params = { protectionCaseId, benefitId };

    return this.apiService
      .post(this.API_NAME, "saveIncomeBenefitHandover", incomeBenefit, {
        params,
      })
      .toPromise();
  }

  public savePersonalBenefitHandover(
    protectionCaseId: string,
    benefitId: string,
    personalBenefit: PersonalBenefitHandoverDTO
  ): Promise<void> {
    const params = { protectionCaseId, benefitId };

    return this.apiService
      .post(this.API_NAME, "savePersonalBenefitHandover", personalBenefit, {
        params,
      })
      .toPromise();
  }

  public saveMortgageBenefitHandover(
    protectionCaseId: string,
    benefitId: string,
    mortgageBenefit: MortgageBenefitHandoverDTO
  ): Promise<void> {
    const params = { protectionCaseId, benefitId };

    return this.apiService
      .post(this.API_NAME, "saveMortgageBenefitHandover", mortgageBenefit, {
        params,
      })
      .toPromise();
  }

  public saveHandoverGeneral(
    protectionCaseId: string,
    handoveGeneral: ProtectionHandoverGeneralDTO
  ): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .post(this.API_NAME, "saveHandoverGeneral", handoveGeneral, { params })
      .toPromise();
  }

  public async viewComplianceHandover(
    protectionCaseId: string
  ): Promise<ProtectionComplianceHandoverViewDTO> {
    const params = { protectionCaseId };

    return this.apiService
      .get(this.API_NAME, "viewComplianceHandover", { params })
      .toPromise();
  }

  public async viewMortgageBenefitHandover(
    protectionCaseId: string,
    benefitId: string
  ): Promise<MortgageBenefitHandoverViewDTO> {
    const params = { protectionCaseId, benefitId };

    return this.apiService
      .get(this.API_NAME, "viewMortgageBenefitHandover", { params })
      .toPromise();
  }

  public async viewPersonalBenefitHandover(
    protectionCaseId: string,
    benefitId: string
  ): Promise<PersonalBenefitHandoverViewDTO> {
    const params = { protectionCaseId, benefitId };

    return this.apiService
      .get(this.API_NAME, "viewPersonalBenefitHandover", { params })
      .toPromise();
  }

  public async viewIncomeBenefitHandover(
    protectionCaseId: string,
    benefitId: string
  ): Promise<IncomeBenefitHandoverViewDTO> {
    const params = { protectionCaseId, benefitId };

    return this.apiService
      .get(this.API_NAME, "viewIncomeBenefitHandover", { params })
      .toPromise();
  }

  public async viewClientSheet(
    protectionCaseId: string
  ): Promise<ProtectionClientSheetDTO> {
    const params = { protectionCaseId };

    return this.apiService
      .get(this.API_NAME, "viewClientSheet", { params })
      .toPromise();
  }

  public approveComplianceHandover(
    protectionCaseId: string,
    body: { value: string }
  ): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .post(this.API_NAME, "approveComplianceHandover", body, { params })
      .toPromise();
  }

  public undoComplianceHandoverApproval(
    protectionCaseId: string
  ): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .post(this.API_NAME, "undoComplianceHandoverApproval", undefined, {
        params,
      })
      .toPromise();
  }

  public changeProviderReference(
    protectionApplicationId: string,
    providerReference: ProviderReferenceDTO
  ): Promise<void> {
    const params = { protectionApplicationId };

    return this.apiService
      .post(this.API_NAME, "changeProviderReference", providerReference, {
        params,
      })
      .toPromise();
  }

  public setProtectionNotProceeding(
    protectionCaseId: string,
    journalWithDocument: JournalWithReasonDTO
  ): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .post(this.API_NAME, "setProtectionNotProceeding", journalWithDocument, {
        params,
      })
      .toPromise();
  }

  public deleteProtection(protectionCaseId: string): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .delete(this.API_NAME, "deleteProtection", { params })
      .toPromise();
  }

  public reassignAdvisor(
    protectionCaseId: string,
    advisorId: string
  ): Promise<void> {
    const params = { protectionCaseId, advisorId };

    return this.apiService
      .post(this.API_NAME, "reassignAdvisor", undefined, { params })
      .toPromise();
  }

  public reactivateProtection(protectionCaseId: string): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .post(this.API_NAME, "reactivateProtection", undefined, { params })
      .toPromise();
  }

  public requestFactFind(
    protectionCaseId: string,
    journal: JournalBasic
  ): Promise<void> {
    const params = { protectionCaseId };

    return this.apiService
      .post(this.API_NAME, "requestFactFind", journal, { params })
      .toPromise();
  }

  public switchBenefitCategory(
    protectionCaseId: string,
    benefitId: string
  ): Promise<void> {
    const params = { protectionCaseId, benefitId };

    return this.apiService
      .post(this.API_NAME, "switchBenefitCategory", undefined, { params })
      .toPromise();
  }

  public getImportedData(
    protectionCaseId: string
  ): Observable<ProtectionImportedDataDTO> {
    const params = { protectionCaseId };

    return this.apiService.get<ProtectionImportedDataDTO>(
      this.API_NAME,
      "getImportedData",
      { params }
    );
  }

  public getClientNeeds(
    protectionCaseId: string
  ): Observable<ListResult<ClientNeed>> {
    const params = { protectionCaseId };

    return this.apiService.get<ProtectionImportedDataDTO>(
      this.API_NAME,
      "getClientNeeds",
      { params }
    );
  }

  public getClientNeed(needId: string): Observable<ClientNeed> {
    const params = { needId };

    return this.apiService.get<ProtectionImportedDataDTO>(
      this.API_NAME,
      "getClientNeed",
      { params }
    );
  }

  public createClientNeed(
    protectionCaseId: string,
    dto: ClientNeed
  ): Observable<ListResult<ClientNeed>> {
    const params = { protectionCaseId };

    return this.apiService.post<ProtectionImportedDataDTO>(
      this.API_NAME,
      "createClientNeed",
      dto,
      { params }
    );
  }

  public editClientNeed(
    needId: string,
    dto: ClientNeed
  ): Observable<ListResult<ClientNeed>> {
    const params = { needId };

    return this.apiService.post<ProtectionImportedDataDTO>(
      this.API_NAME,
      "editClientNeed",
      dto,
      { params }
    );
  }

  public deleteClientNeed(needId: string): Observable<ListResult<ClientNeed>> {
    const params = { needId };

    return this.apiService.delete<ProtectionImportedDataDTO>(
      this.API_NAME,
      "deleteClientNeed",
      { params }
    );
  }

  public getSuitabilityLetterPreview(
    protectionCaseId: string,
    handoverId?: string
  ): Observable<SuitabilityLetterDTO> {
    const params = { protectionCaseId, handoverId };
    handoverId || delete params.handoverId;

    return this.apiService.get(this.API_NAME, "getSuitabilityLetterPreview", {
      params,
    });
  }

  public sendSuitabilityLetter(
    protectionCaseId: string,
    handoverId?: string
  ): Observable<any> {
    const params = { protectionCaseId, handoverId };
    handoverId || delete params.handoverId;

    return this.apiService.post(this.API_NAME, "sendSuitabilityLetter", null, {
      params,
    });
  }

  private prepareProtectionApplicationDTO(
    protectionApplication: ProtectionApplicationDTO
  ) {
    this.removeTempIds(protectionApplication);

    if (protectionApplication.expiryDate) {
      protectionApplication.expiryDate = toLocalDateISO(
        protectionApplication.expiryDate
      ) as any;
    }
  }

  private removeTempIds(protectionApplication: ProtectionApplicationDTO) {
    protectionApplication.benefits.forEach((benefit) => {
      if (`${benefit.id}`.startsWith("_")) {
        delete benefit.id;
      }
    });
  }
}
