
export type JournalCategory =
  | "OTHER"
  | "STATE_UPDATE"
  | "NOTE"
  | "APPOINTMENT"
  | "DOCUMENT"
  | "REASSIGN"
  | "SYSTEM"
  | "EDIT"
  | "EMAIL_IN"
  | "EMAIL_OUT"
  | "EMAIL_OPTED_OUT"
  | "FACT_FIND"
  | "MORTGAGE_CREATED"
  | "DELETED"
  | "FF_REQUESTED"
  | "FF_COMPLETED"
  | "FF_SIGNED"
  | "MESSAGE"
  | "LETTER"
  | "CONTACT"
  | "CONTACT_IN"
  | "CONTACT_OUT"
  | "TASK"
  | "TASK_CREATED"
  | "TASK_COMPLETED"
  | "PINNED"
  | "YOURKEYS_FAILURE";

export const JournalCategoryIcon = new Map<JournalCategory, string>([
  ["OTHER", "timeline/other.svg"],
  ["STATE_UPDATE", "timeline/status_changed.svg"],
  ["YOURKEYS_FAILURE", "timeline/status_changed.svg"],
  ["NOTE", "timeline/Note.svg"],
  ["APPOINTMENT", "timeline/event.svg"],
  ["DOCUMENT", "timeline/Document_created.svg"],
  ["REASSIGN", "timeline/Reassign.svg"],
  ["SYSTEM", "timeline/cloud.svg"],
  ["EDIT", "timeline/edit.svg"],
  ["EMAIL_IN", "timeline/Email_in.svg"],
  ["EMAIL_OUT", "timeline/Email_out.svg"],
  ["EMAIL_OPTED_OUT", "timeline/other.svg"],
  ["FACT_FIND", "timeline/Fact_find_requested.svg"],
  ["MORTGAGE_CREATED", "timeline/mortgage.svg"],
  ["DELETED", "timeline/deleted.svg"],
  ["FF_REQUESTED", "timeline/Fact_find_requested.svg"],
  ["FF_COMPLETED", "timeline/Fact_find_requested.svg"],
  ["FF_SIGNED", "timeline/Fact_find_requested.svg"],
  ["MESSAGE", "timeline/email.svg"],
  ["LETTER", "timeline/other.svg"],
  ["CONTACT", "timeline/phone.svg"],
  ["CONTACT_IN", "timeline/phone_in-1.svg"],
  ["CONTACT_OUT", "timeline/phone_in.svg"],
  ["TASK", "timeline/task_done.svg"], // Added because of missing icon
  ["TASK_CREATED", "timeline/task_done.svg"],
  ["TASK_COMPLETED", "timeline/task_done.svg"],
  ["PINNED", "timeline/Pin.svg"],
]);

export type EmailJournalState =
  | "SENT"
  | "NOT_DELIVERED"
  | "DELIVERED"
  | ""

export const EmailJournalStateIcon = new Map<EmailJournalState, string>([
  ["SENT", "email-events/sent.svg"],
  ["NOT_DELIVERED", "email-events/failed.svg"],
  ["DELIVERED", "email-events/delivered.svg"],
  ["", "email-events/failed.svg"],
]);

export const EmailJournalStateName = new Map<EmailJournalState, string>([
  ["SENT", "Sent"],
  ["NOT_DELIVERED", "Not Delivered"],
  ["DELIVERED", "Delivered"],
]);

export type EmailJournalType =
  | "SENT"
  | "RECEIVED"

export type EmailEventType =
  | "dropped"
  | "deferred"
  | "bounced"
  | "delivered"
  | "open"
  | "clicked"
  | "processed"
  | "spamreport"
  | "unsubscribe"
  | "group_unsubscribe"
  | "group_resubscribe"
  | ""

export const EmailEventTypeName = new Map<EmailEventType, string>([
  ["dropped", "Dropped"],
  ["deferred", "Deferred"],
  ["bounced", "Bounced"],
  ["delivered", "Delivered"],
  ["open", "Opened"],
  ["clicked", "Clicked"],
  ["processed", "Sent"],
  ["spamreport", "Spamreport"],
  ["unsubscribe", "Unsubscribe"],
  ["group_unsubscribe", "Group Unsubscribe"],
  ["group_resubscribe", "Group Resubscribe"],
]);

export const EmailEventTypeIcon = new Map<EmailEventType, string>([
  ["delivered", "email-events/delivered.svg"],
  ["open", "email-events/opened.svg"],
  ["clicked", "email-events/clicked.svg"],
  ["processed", "email-events/sent.svg"],
  ["", "email-events/failed.svg"],
]);

